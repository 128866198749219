import React from "react";
import {DropdownItem} from "reactstrap";
import {NavLink as Link} from "react-router-dom";

export interface IMenuItem {
    icon: string;
    to?: string;
    id?: string;
    onClick?: any;
    "data-cy"?: string;
}

export default class MenuItem extends React.Component<IMenuItem> {
    render() {
        const {to, icon, id, children, onClick} = this.props;
        if (to) {
            return (
                <DropdownItem
                    tag={Link}
                    to={to}
                    id={id}
                    data-cy={this.props["data-cy"]}
                    onClick={onClick}
                >
                    <i className={`${icon}`}/> {children}
                </DropdownItem>
            );
        } else {
            return (
                <span
                    className="dropdown-item"
                    id={id}
                    data-cy={this.props["data-cy"]}
                    onClick={onClick}
                >
                    <i className={`${icon}`} style={{color: "#DCA447"}}/> {children}
                </span>
            );
        }
    }
}
