import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Row,
    Col,
    FormFeedback,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

import { useAppDispatch, useAppSelector } from "app/config/store";
import { getSession } from "app/shared/reducers/authentication";
import { updateAccount } from "./settings.reducer";

import { useFormik } from "formik";
import * as Yup from "yup";

const UserSchema = Yup.object().shape({
    fullName: Yup.string().trim()
        .required("Vui lòng nhập họ và tên!")
        .max(50, "Họ và tên chỉ được phép nhập tối đa 50 ký tự!"),
});

export const SettingsPage = ({ isOpenUserSettingPopup, handleClose, history }) => {
    const fullNameRef = useRef(null);
    const dispatch = useAppDispatch();

    const account = useAppSelector((state) => state.authentication.account);
    const [initAccount, setInnitAccount] = useState(account);
    const isAuthenticated = useAppSelector(
        (state) => state.authentication.isAuthenticated
    );

    const handleFomikSubmit = (values, { setSubmitting }) => {
        const handleData = handleBeforeSubmit(values);
        dispatch(updateAccount(handleData));
        setSubmitting(false);
    };

    const handleBeforeSubmit = (values) => {
        return {
            ...values,
            fullName: values.fullName.trim(),
            lastModifiedBy: account.email
        }
    }

    const formik = useFormik({
        initialValues: initAccount,
        onSubmit: handleFomikSubmit,
        enableReinitialize: true,
        validationSchema: UserSchema,
    });

    const updateSuccess = useAppSelector(
        (state) => state.settings.updateSuccess
    );

    useEffect(() => {
        if (account) {
            setInnitAccount(account);
        }
    }, [account]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(getSession());
            handleClose();
            formik.resetForm();
        }
    }, [updateSuccess]);

    return (
        <Modal
            isOpen={isOpenUserSettingPopup}
            toggle={() => {
                handleClose();
                formik.resetForm();
            }}
            backdrop="static"
            centered={true}
            size="md"
            style={{ width: "100%" }}
        >
            <ModalHeader
                toggle={() => {
                    handleClose();
                    formik.resetForm();
                }}
            >
                Thiết lập tài khoản
            </ModalHeader>
            <ModalBody>
                {isAuthenticated && (
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col span={12}>
                                <div className="input-container mb-25">
                                    <Input
                                        innerRef={fullNameRef}
                                        maxLength={50}
                                        className="text-input"
                                        invalid={
                                            !!formik.errors.fullName &&
                                            !!formik.touched.fullName
                                        }
                                        type="text"
                                        name="fullName"
                                        placeholder="Họ và tên"
                                        value={formik.values.fullName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label
                                        htmlFor="fullname"
                                        className="label"
                                        onClick={() =>
                                            fullNameRef.current.focus()
                                        }
                                    >
                                        Họ và tên{" "}
                                        <span className="color-red">*</span>
                                    </label>

                                    {!!formik.errors.fullName &&
                                        !!formik.touched.fullName && (
                                            <>
                                                <FormFeedback>
                                                    {formik.errors.fullName}
                                                </FormFeedback>
                                                <div className="mb-negative-25"></div>
                                            </>
                                        )}
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end mt-40">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                                style={{ marginRight: "12px" }}
                            >
                                <i className="fas fa-save"></i>
                                Lưu
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose();
                                    formik.resetForm();
                                }}
                                color="secondary"
                            >
                                <i className="fas fa-ban"></i>
                                Bỏ qua
                            </Button>
                        </div>
                    </form>
                )}
            </ModalBody>
        </Modal>
    );
};

export default SettingsPage;
