import axios, {AxiosResponse} from "axios";
import {Storage} from "react-jhipster";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";

import {AppThunk} from "app/config/store";

const AUTH_TOKEN_KEY = "jhi-authenticationToken";

export const initialState = {
    loading: false,
    isAuthenticated: false,
    loginSuccess: false,
    loginError: false, // Errors returned from server side
    LockoutEnd: null as unknown as string,
    showModalLogin: false,
    account: {} as any,
    errorMessage: null as unknown as string, // Errors returned from server side
    redirectMessage: null as unknown as string,
    sessionHasBeenFetched: false,
    logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => (dispatch, getState) => {
    dispatch(getAccount());
};

export const getAccount = createAsyncThunk(
    "authentication/get_account",
    async () => axios.get<any>("api/account"),
    {
        serializeError: serializeAxiosError,
    }
);

interface IAuthParams {
    username: string;
    password: string;
    rememberMe?: boolean;
}

export const authenticate = createAsyncThunk(
    "authentication/login",
    async (auth: IAuthParams) => axios.post<any>("api/authenticate", auth),
    {
        serializeError: serializeAxiosError,
    }
);

export const login: (
    username: string,
    password: string,
    history?: any,
    rememberMe?: boolean,
) => AppThunk =
    (username, password, history, rememberMe = false) =>
        async (dispatch) => {
            const result = await dispatch(
                authenticate({username, password, rememberMe})
            );
            const response = result.payload as AxiosResponse;
            const bearerToken = response?.headers?.authorization;
            let jwt = null;
            if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
                jwt = bearerToken.slice(7, bearerToken.length);
            } else if (response && response.data) {
                jwt = response.data.id_token;
            }
            if (jwt) {
                Storage.local.set(AUTH_TOKEN_KEY, jwt);
                Storage.session.set(AUTH_TOKEN_KEY, jwt);
            }
            if (response && response.data) {
                history.push("/permision");
                dispatch(getSession());
            }
            else {
                dispatch(lockUser(username));
            }
        };

export const LockoutEnd = createAsyncThunk(
    "authentication/LockoutEnd",
    async (username: string) =>
        axios.post<any>("api/account/get-lock-out-end", username, {
            headers: {["Content-Type"]: "text/plain"},
        }),
    {
        serializeError: serializeAxiosError,
    }
);

export const getLockoutEnd: (
    username: string
) => AppThunk =
    (username) =>
        async (dispatch) => {
            await dispatch(LockoutEnd(username));
        };

export const lock = createAsyncThunk(
    "authentication/Lock",
    async (username: string) =>
        axios.post<any>("api/account/lock", username, {
            headers: {["Content-Type"]: "text/plain"},
        }),
    {
        serializeError: serializeAxiosError,
    }
);

export const lockUser: (
    username: string
) => AppThunk =
    (username) =>
        async (dispatch) => {
            const result = await dispatch(lock(username));
            const response = result.payload as AxiosResponse;
            if (!response) {
                dispatch(getLockoutEnd(username));
            }
        };

export const clearAuthToken = () => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
        Storage.local.remove(AUTH_TOKEN_KEY);
    }
    if (Storage.session.get(AUTH_TOKEN_KEY)) {
        Storage.session.remove(AUTH_TOKEN_KEY);
    }
};

export const logout: (history) => AppThunk = (history) => (dispatch) => {
    clearAuthToken();
    dispatch(logoutSession());
    if (history) {
        history.push("/");
    }
};

export const clearAuthentication = (messageKey) => (dispatch) => {
    clearAuthToken();
    dispatch(authError(messageKey));
    dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
    name: "authentication",
    initialState: initialState as AuthenticationState,
    reducers: {
        updateAccount(state, action) {
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.payload,
                },
            };
        },
        logoutSession() {
            return {
                ...initialState,
                showModalLogin: true,
            };
        },
        authError(state, action) {
            return {
                ...state,
                showModalLogin: true,
                redirectMessage: action.payload,
            };
        },
        clearAuth(state) {
            return {
                ...state,
                loading: false,
                showModalLogin: true,
                isAuthenticated: false,
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(authenticate.rejected, (state, action) => ({
                ...initialState,
                errorMessage: action.error.message,
                showModalLogin: true,
                loginError: true,
            }))
            .addCase(authenticate.fulfilled, (state) => ({
                ...state,
                loading: false,
                loginError: false,
                showModalLogin: false,
                loginSuccess: true,
            }))
            .addCase(getAccount.rejected, (state, action) => ({
                ...state,
                loading: false,
                isAuthenticated: false,
                sessionHasBeenFetched: true,
                showModalLogin: true,
                errorMessage: action.error.message,
            }))
            .addCase(getAccount.fulfilled, (state, action) => {
                const isAuthenticated =
                    action.payload &&
                    action.payload.data &&
                    action.payload.data.activated;
                return {
                    ...state,
                    isAuthenticated,
                    loading: false,
                    sessionHasBeenFetched: true,
                    account: action.payload.data,
                };
            })
            .addCase(authenticate.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(LockoutEnd.pending, (state, action) => {
                return {
                    ...state,
                    LockoutEnd: null,
                };
            })
            .addCase(LockoutEnd.fulfilled, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    sessionHasBeenFetched: true,
                    LockoutEnd: action.payload.data,
                };
            });
    },
});

export const {updateAccount, logoutSession, authError, clearAuth} =
    AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
