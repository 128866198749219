import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending
} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { ITitleLevel, defaultValue } from "app/shared/model/title-level.model";

const initialState: EntityState<ITitleLevel> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

const apiUrl = "api/title-levels";

// Actions

export const getEntities = createAsyncThunk(
  "titleLevel/fetch_entity_list",
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
    return axios.get<ITitleLevel[]>(requestUrl, {params: {page, size, sort}});
  }
);

export const getAllTitleLevelWidthoutPagination = createAsyncThunk(
  "titleLevel/fetch_titlelevel_list_widthout_pagination",
  async () => {
    const requestUrl = `${apiUrl}/all`;
    return axios.get<ITitleLevel[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "titleLevel/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITitleLevel>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "titleLevel/create_entity",
  async (entity: ITitleLevel, thunkAPI) => {
    return axios.post<ITitleLevel>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "titleLevel/update_entity",
  async (entity: ITitleLevel, thunkAPI) => {
    return axios.put<ITitleLevel>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "titleLevel/partial_update_entity",
  async (entity: ITitleLevel, thunkAPI) => {
    return axios.patch<ITitleLevel>(`${apiUrl}/${entity.id}`,cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "titleLevel/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ITitleLevel>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const TitleLevelSlice = createEntitySlice({
  name: "titleLevel",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(
            action.payload.headers["x-total-count"],
            10
          )
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = TitleLevelSlice.actions;

// Reducer
export default TitleLevelSlice.reducer;
