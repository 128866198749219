import axios from "axios";
import { Storage } from "react-jhipster";

const TIMEOUT = 20 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.SERVER_API_URL || "";

const axiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (config) => {
    const token =
      Storage.local.get("jhi-authenticationToken") ||
      Storage.session.get("jhi-authenticationToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response) => response;
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default axiosInterceptors;
