import React from "react";

import {NavbarBrand} from "reactstrap";
import {NavLink as Link} from "react-router-dom";

export const BrandIcon = (props) => (
    <span {...props} className="logo">
        <span className="logo-lg">
            <img
                src="content/images/logos/logo-vinhomes.svg"
                alt="Icon"
                height="50"
                width="100%"
            />
        </span>
    </span>
);

export const Brand = () => (
    <NavbarBrand tag={Link} to="/permision" className="brand-logo">
        <BrandIcon/>
    </NavbarBrand>
);


