import React, {useEffect, useRef, useState} from "react";
import {Button, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import {useAppDispatch, useAppSelector} from "app/config/store";
import {getSession} from "app/shared/reducers/authentication";
import {reset, savePassword} from "./password.reducer";
import * as Yup from "yup";
import {useFormik} from "formik";
import PasswordStrengthBar from "app/shared/layout/password/password-strength-bar";
import {toast} from "react-toastify";
import { REGEX_PASSWORD } from "app/config/constants";

export const PasswordPage = ({ isOpenChangePasswordPopup, handleClose }) => {
    const currentPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const dispatch = useAppDispatch();

    const [isOpen, setLoadModal] = useState(false);

    useEffect(() => {
        setLoadModal(isOpenChangePasswordPopup);
    }, [isOpenChangePasswordPopup]);

    const handleCloseModal = () => {
        setLoadModal(false);
        handleClose();
        formik.resetForm();
    };


    const PasswordSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required("Vui lòng nhập!")
            .max(50, "Mật khẩu chỉ được phép nhập tối đa 50 ký tự!")
            .matches(
                REGEX_PASSWORD,
                "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số!"
            ),
        newPassword: Yup.string()
            .required("Vui lòng nhập!")
            .max(50, "Mật khẩu chỉ được phép nhập tối đa 50 ký tự!")
            .matches(
                REGEX_PASSWORD,
                "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số!"
            ),
        confirmPassword: Yup.string()
            .required("Vui lòng nhập!")
            .oneOf(
                [Yup.ref("newPassword"), null],
                "Mật khẩu không trùng khớp!"
            )
            .max(50, "Mật khẩu chỉ được phép nhập tối đa 50 ký tự!")
            .matches(
                REGEX_PASSWORD,
                "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số!"
            )
    })


    useEffect(() => {
        dispatch(reset());
        dispatch(getSession());
        return () => {
            dispatch(reset());
        };
    }, []);


    const handleFormikSubmit = (values, { setSubmitting }) => {
        const bodyData = {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            lastModifiedBy: account.email
        }
        setSubmitting(false);
        dispatch(savePassword(bodyData));
    }

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        onSubmit: handleFormikSubmit,
        validationSchema: PasswordSchema
    })


    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated)
    const account = useAppSelector((state) => state.authentication.account);
    const successMessage = useAppSelector(
        (state) => state.password.successMessage
    );

    useEffect(() => {
        if (successMessage) {
            toast.success("Đặt mật khẩu mới thành công");
            handleCloseModal();
        }
    }, [successMessage]);

    return (
        <>

            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleCloseModal();
                }}
                backdrop="static"
                centered={true}
                size="lg"
            >
                <ModalHeader
                    toggle={() => {
                        handleCloseModal();
                    }}
                >
                    Mật khẩu dành cho {account.login}
                </ModalHeader>
                <ModalBody>
                    {
                        isAuthenticated && (
                            <form onSubmit={formik.handleSubmit} className="needs-validation">
                                <Row>
                                    <Col lg={12}>
                                        <div className="input-container mb-25">
                                            <Input type="password"
                                                name="currentPassword"
                                                className="text-input"
                                                placeholder="Nhập thông tin mật khẩu"
                                                maxLength={50}
                                                invalid={!!formik.errors.currentPassword && !!formik.touched.currentPassword}
                                                value={formik.values.currentPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                innerRef={currentPasswordRef}
                                            />
                                            <Label htmlFor="currentPassword"
                                                onClick={() => currentPasswordRef.current.focus()}
                                                className="label">
                                                Mật khẩu hiện tại {" "}
                                                <span className="color-red">*</span>
                                            </Label>
                                            {formik.errors.currentPassword && formik.touched.currentPassword && (
                                                <>
                                                    <FormFeedback>
                                                        {formik.errors.currentPassword}
                                                    </FormFeedback>
                                                    <div className="mb-negative-25"></div>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="input-container mb-25">
                                            <Input type="password" name="newPassword"
                                                className="text-input"
                                                placeholder="Nhập thông tin mật khẩu"
                                                maxLength={50}
                                                invalid={!!formik.errors.newPassword && !!formik.touched.newPassword}
                                                value={formik.values.newPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                innerRef={newPasswordRef}
                                            />
                                            <Label htmlFor="newPassword"
                                                onClick={() => newPasswordRef.current.focus()}
                                                className="label">Mật khẩu mới {" "}
                                                <span className="color-red">*</span></Label>
                                            {!!formik.errors.newPassword && !!formik.touched.newPassword && (
                                                <>

                                                    <FormFeedback>
                                                        {formik.errors.newPassword}
                                                    </FormFeedback>
                                                    <div className="mb-negative-25"></div>

                                                </>
                                            )}
                                        </div>
                                    </Col>
                                    <PasswordStrengthBar password={formik.values.newPassword}/>
                                    <Col lg={12}>
                                        <div className="input-container mb-25">
                                            <Input type="password" name="confirmPassword"
                                                className="text-input"
                                                placeholder="Nhập thông tin mật khẩu"
                                                maxLength={50}
                                                invalid={!!formik.errors.confirmPassword && !!formik.touched.confirmPassword}
                                                value={formik.values.confirmPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                innerRef={confirmPasswordRef}
                                            />
                                            <Label htmlFor="confirmPassword"
                                                onClick={() => confirmPasswordRef.current.focus()}
                                                className="label">Xác nhận mật khẩu mới
                                                {" "}
                                                <span className="color-red">*</span></Label>
                                            {!!formik.errors.confirmPassword && !!formik.touched.confirmPassword && (
                                                <>
                                                    <FormFeedback>
                                                        {formik.errors.confirmPassword}
                                                    </FormFeedback>
                                                    <div className="mb-negative-25"></div>

                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end mt-40">
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                                        style={{marginRight: "12px"}}
                                    >
                                        <i className="fas fa-save"></i>
                                        Lưu
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            handleClose();
                                            formik.resetForm();
                                        }}
                                        color="secondary"
                                    >
                                        <i className="fas fa-ban"></i>
                                        Bỏ qua
                                    </Button>

                                </div>
                            </form>
                        )
                    }
                </ModalBody>
            </Modal>
        </>
    );
};

export default PasswordPage;
