import { ITitleLevel } from "app/shared/model/title-level.model";
import { IWork } from "app/shared/model/work.model";

export interface ICnpqType {
  id?: number;
  name?: string;
  order?: string | null;
  description?: string | null;
  nameCNPQ?: string | null;
  titleCode?: string | null;
  activated?: boolean | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  titleLevels?: ITitleLevel[] | null;
  works?: IWork[] | null;
}

export const defaultValue: Readonly<ICnpqType> = {
  id: null,
  name: '',
  order: '',
  description: '',
  nameCNPQ: '',
  titleCode: '',
  activated: true,
  createdDate: '',
  createdBy: '',
  lastModifiedDate: '',
  lastModifiedBy: '',
  titleLevels: [],
  works: []
};
