export interface IUser {
    id?: any;
    login?: string;
    email?: string;
    fullName?: string;
    activated?: boolean;
    langKey?: string;
    authorities?: any[];
    createdBy?: string;
    createdDate?: Date | null;
    lastModifiedBy?: string;
    lastModifiedDate?: Date | null;
    password?: string;
    confirmPassword?: string;
}

export const defaultValue: Readonly<IUser> = {
    id: null,
    email: "",
    login: "",
    fullName: "",
    activated: true,
    langKey: "",
    authorities: [],
    createdBy: "",
    createdDate: null,
    lastModifiedBy: "",
    lastModifiedDate: null,
    password: "",
    confirmPassword: "",
};
