import { AUTHORITIES } from "app/config/constants";
import { ApproveType } from "../model/enumerations/approve-type.model";

export const authorityLabel = ({ authority }) => {
    if (AUTHORITIES.ADMIN === authority) {
        return "Quản trị hệ thống";
    }
    if (AUTHORITIES.USER === authority) {
        return "Người dùng";
    }
    return "";
};

const getCnpqTypeId = (value) => {
    if (value && typeof value === "string") {
        return value;
    }
    if (value && typeof value === "object" && value.length > 0) {
        return value[0].value;
    }

    return null;
};

export const updateCnpqTypeOptions = ({
    dispatch,
    newFormDataCnpqTypes,
    cnpqTypes,
    setCnpqTypesOptions,
}) => {
    const newCnpqOptions = [];

    newFormDataCnpqTypes.forEach((cnpqType) => {


        const cnpqOptionsItem = getCnpqTypeOptions({
            cnpqTypes,
            cnpqTypesForm: newFormDataCnpqTypes,
            fieldValue: getCnpqTypeId(cnpqType.cnpqTypeId),
        });

        newCnpqOptions.push(cnpqOptionsItem);
    });

    dispatch(setCnpqTypesOptions(newCnpqOptions));
};

export const getCnpqTypeOptions = ({
    cnpqTypes,
    fieldValue,
    cnpqTypesForm,
}) => {
    const selectOptions = cnpqTypes.map((cnpq) => {
        return { value: cnpq.id, label: cnpq.name };
    });

    const optionsWithoutSelectedList = selectOptions.reduce(
        (prevValue, currentValue) => {
            /* The selected cell value is equal to the value in the list
             *  will return that selection in the list cell
             */
            const fieldValueNumber = parseFloat(fieldValue);
            const currentValueNumber = parseFloat(currentValue.value);

            if (currentValueNumber === fieldValueNumber) {
                return [...prevValue, currentValue];
            }

            // The selected cell value is not equal to the value in the list
            if (currentValueNumber !== fieldValueNumber) {
                const selectedOptionInForm = cnpqTypesForm.filter(
                    (cnpqTypeForm) => getCnpqTypeId(cnpqTypeForm.cnpqTypeId) !== ""
                    );
                // Selections in the form list have selected the form will not return that selection in the list cell
                if (
                    selectedOptionInForm.some(
                        (cnpqTypeForm) =>
                            parseFloat(getCnpqTypeId(cnpqTypeForm.cnpqTypeId)) === currentValueNumber
                    )
                ) {
                    return prevValue;
                }
            }

            // Rest option
            return [...prevValue, currentValue];
        },
        []
    );

    return optionsWithoutSelectedList.map(optionsWithoutSelected => ({
        ...optionsWithoutSelected,
        value: `${optionsWithoutSelected.value}`
    }));
};

const getUniqueErrorMessagesByFieldName = ({errors, fieldName}) => {
    const uniqueMessage = [];

    const cnpqTypesNameErrorsUnique = errors.reduce(
        (prevErrors, currentError) => {
            if (
                prevErrors.some(
                    (prevError) =>
                        prevError[fieldName] === currentError[fieldName]
                )
            ) {
                return prevErrors;
            }
            return prevErrors.concat(currentError);
        },
        []
    );
    cnpqTypesNameErrorsUnique.forEach((error) => {
        uniqueMessage.push(error[fieldName]);
    });

    return uniqueMessage;
}

export const getErrorCnpqTypesMessageList = ({ errorsFormik }) => {
    if (errorsFormik?.cnpqTypes) {
        const cnpqTypesErrors = errorsFormik.cnpqTypes.filter(
            (cnpqType) => cnpqType && getCnpqTypeId(cnpqType.cnpqTypeId) !== ""
        );

        // Cnpq Type Name
        const cnpqTypeNameErrorMessages = getUniqueErrorMessagesByFieldName({errors: cnpqTypesErrors, fieldName: "cnpqTypeId"});
        // Name CNPQ
        const nameCNPQErrorMessages = getUniqueErrorMessagesByFieldName({errors: cnpqTypesErrors, fieldName: "nameCNPQ"});
        // Order
        const orderErrorMessages = getUniqueErrorMessagesByFieldName({errors: cnpqTypesErrors, fieldName: "order"});
        // Title Code
        const titleCodeErrorMessages = getUniqueErrorMessagesByFieldName({errors: cnpqTypesErrors, fieldName: "titleCode"});
        // Description
        const descriptionErrorMessages = getUniqueErrorMessagesByFieldName({errors: cnpqTypesErrors, fieldName: "description"});

        return cnpqTypeNameErrorMessages.concat(nameCNPQErrorMessages, orderErrorMessages, titleCodeErrorMessages, descriptionErrorMessages);
    }

    return [];

};

export const updateErrorMessagesCnpqTypes = ({
    newTable,
    setErrorMessages
}) => {
    const newErrorMessages = [];

    const isNameInvalid = newTable.some(
        (newStateCnpq) => {
            if (typeof newStateCnpq.cnpqTypeId === "string" && newStateCnpq.cnpqTypeId === "") {
                return true;
            }
            if (typeof newStateCnpq.cnpqTypeId === "object" && (!newStateCnpq.cnpqTypeId || newStateCnpq.cnpqTypeId.length === 0)) {
                return true;
            }
            return false;
        }
    );
    const isNameCNPQInvalid = newTable.some(
        (newStateCnpq) => newStateCnpq.nameCNPQ.length > 2000
    );
    const isOrderInvalid = newTable.some(
        (newStateCnpq) => newStateCnpq.order.replace(/<[^>]+>/g, '').length > 20
    );
    const isTitleCodeInvalid = newTable.some(
        (newStateCnpq) => newStateCnpq.titleCode.length > 20
    );
    const isDescriptionInvalid = newTable.some(
        (newStateCnpq) => newStateCnpq.description.length > 500
    );

    if (isNameInvalid) {
        newErrorMessages.push("Vui lòng nhập loại CNPQ!");
    }

    if (isNameCNPQInvalid) {
        newErrorMessages.push("Tên trên CNPQ chỉ được phép nhập tối đa 2000 ký tự!");
    }

    if (isOrderInvalid) {
        newErrorMessages.push("Số thứ tự chỉ được phép nhập tối đa 20 ký tự!");
    }

    if (isTitleCodeInvalid) {
        newErrorMessages.push("Mã văn bản chỉ được phép nhập tối đa 20 ký tự!");
    }

    if (isDescriptionInvalid) {
        newErrorMessages.push("Ghi chú chỉ được phép nhập tối đa 500 ký tự!");
    }

    setErrorMessages(newErrorMessages);
};

export const handleSyncList = ({pagination, setPagination, filter, dispatch, getEntities}) => {
    const newActivePage = pagination.activePage ? pagination.activePage : 1;
    setPagination({
        ...pagination,
        activePage: newActivePage
    })
    const body: any = {
        page: newActivePage - 1,
        size: pagination.itemsPerPage,
        // sort: `${pagination.sort},${pagination.order}`
    }
    if (filter) {
        body.filter = filter
    }
    dispatch(
        getEntities(body)
      );
}
// Hàm hỗ trợ để lấy nhãn từ giá trị enum
export const getLabelFromValue = (value) => {
    for (const key in ApproveType) {
      // eslint-disable-next-line no-prototype-builtins
      if (ApproveType.hasOwnProperty(key)) {
        if (ApproveType[key] === value) {
          return key;
        }
      }
    }
    return null; // Giá trị không hợp lệ
  }

export const cleanString = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
  
    const delElements = doc.getElementsByTagName('del');
    for (let i = delElements.length - 1; i >= 0; i--) {
      const delElement = delElements[i];
      delElement.parentNode.removeChild(delElement);
    }
  
    return doc.body.innerHTML;
  };