import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";


import { useAppDispatch, useAppSelector } from "app/config/store";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ChevronDownLight } from "app/assets/images/icons";
import { getChildrenEntities as getChildrenTitles, getAllTitleWidthoutPagination as getTitles } from "app/modules/title/title.reducer";
import { getEntity as getWork } from "app/modules/work/work.reducer";
import { ApproveType } from "app/shared/model/enumerations/approve-type.model";
import { defaultValue } from "app/shared/model/permision.model";
import { getOptions } from "app/shared/util/select-options-utils";
import { AxiosResponse } from "axios";
import _ from "lodash";
import SVG from "react-inlinesvg";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import {
  getChildrenEntities,
  updateEntity,
  warning,
} from "./permision.reducer";

const editorConfiguration = {
  toolbar: ['bold'],
}


export const PermisionUpdate = ({ selected,
  opened,
  CnpqType,
  handleCloseModal,
  disabled
 }: {
    selected: any;
    opened: boolean;
    CnpqType: any;
    handleCloseModal: any;
    disabled: boolean
  }) => {
  const permissionRef = useRef([]);
  const titleRef = useRef([]);
  const permisionTitlesRef = useRef([]);
  const departmentRequiresChangeRef = useRef([]);
  const changeReasonRef = useRef([]);
  const appraisalTypeRef = useRef([]);

  const dispatch = useAppDispatch();

  const [isOpened, setOpen] = useState(opened);
  const [initData, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [parents, setTitleParents] = useState([]);
  const [childrens, setTitleChildrens] = useState([]);
  const [titles, setTitles] = useState([]);
  const entitywork = useAppSelector((state) => state.work.entity);
  const permisionEntities = useAppSelector((state) => state.permision.childrenEntities);
  const account = useAppSelector((state) => state.authentication.account);
  const updateSuccess = useAppSelector((state) => state.permision.updateSuccess);

  const approveTypeValues = Object.entries(ApproveType).filter(([key]) => key !== "No").map(([key, value]) => ({ key, value }));

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownLight />
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const addOptions = (array) => {
    if (array && array.length > 0) {
      return array.map((x) => ({
        ...x,
        value: x.key,
        label: x.value
      }));
    }
    return array;
  }

  const getListErrors = (errorsValue) => {
    return _.find(errorsValue, function (element) {
      if (element && Object.keys(element).length !== 0) {
        return true;
      }
    });
  };

  useEffect(() => {
    if (titles && titles.length > 0) {
      const lstparent = _.filter(titles, function (el) {
        return !el.parentTitle;
      })
      if (lstparent) {
        setTitleParents(lstparent);
      }
    }
  }, [titles]);

  useEffect(() => {
    handleDisabledButton(initData);
  }, [initData]);

  useEffect(() => {
    if (isOpened) {
      handleOnload()
    }
    setOpen(opened)
  }, [opened]);

  useEffect(() => {
    if (selected && CnpqType) {
      const payload = {
        wordId: selected.id,
        cnpqTypeId: CnpqType.id
      }
      dispatch(getChildrenEntities(payload)).then((result: any) => {
        if (result && result.payload.data.length > 0) {
          const dataFormat = [...result.payload.data];
          const newData = dataFormat.map(per => ({
            ...per,
            approveType: per.approveType !== "No" ? per.approveType : null,
            title: per.title && per.title.code.toLowerCase() !== "default" ? {
              ...per.title,
              label: per.title?.name,
              value: per.title?.id
            } : null,
            approveTypes: per.approveType && per.approveType !== "No" ? {
              label: ApproveType[per.approveType],
              value: ApproveType[per.approveType],
            } : null
          }))
          setData(newData);
        }
      });
    }
  }, [selected, CnpqType]);

  useEffect(() => {
    if (entitywork && entitywork.id && (!permisionEntities || permisionEntities.length <= 0)) {
      const coppyData = [...initData];
      const initDataWork = coppyData.map(per => ({
        ...per,
        CnpqTypeId: CnpqType.id,
        work: entitywork,
        WorkId: entitywork?.id
      }))
      setData(initDataWork);
    }
  }, [entitywork]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose(true);
    }
  }, [updateSuccess]);

  const handlchildrenTitle = (value: any, index) => {
    if (value) {
      const dataChanged: any = {
        ...initData[index],
        title: value
      }

      let newPermissionTitles = [];

      delete dataChanged.permisionTitles;
      dispatch(getChildrenTitles(value.id)).then((respone: any) => {
        const titleChildrens = [...childrens]
        if (respone.payload && respone.payload.data.length > 0) {
          newPermissionTitles = respone.payload.data.map(newPermissionTitle => {
            return {
              ...newPermissionTitle,
              value: newPermissionTitle.id,
              label: newPermissionTitle.name,
              TitleId: newPermissionTitle.id
            }
          })
          titleChildrens[index] = respone.payload.data;
          setTitleChildrens(titleChildrens)
          handleErrors("permisionTitles", initData[index].permisionTitles, index);
        }
        else {
          titleChildrens[index] = [];
          setTitleChildrens(titleChildrens)
          handleErrors("permisionTitles", initData[index].permisionTitles, index);
        }
        dataChanged.permisionTitles = newPermissionTitles;
        initData[index] = dataChanged;
        setData(initData);
      });
    }
  }

  const handleOnload = async () => {
    await dispatch(getWork(selected.id));
    await dispatch(getTitles()).then(result => {
      if (result && result.payload) {
        const data: any = result.payload
        setTitles(data)
      }
    });
  }

  const handleDisabledButton = (newInitDataList) => {
    if (disabled) {
      setIsDisabled(true);
      return
    }
    if (newInitDataList.some(newInitData => !newInitData.approveType || !newInitData.title)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }

  const DeleteIcon = ({ i }) => {
    if (initData.length > 1 && i >= 0) {
      return (<div
        className="hand" onClick={() => {
          if (disabled) {
            toast.warning("Không thể xóa phân quyền trong khi chờ xác nhận!")
          } else {
            deleteForm(i)
          }
        }}>
        <i
          className="far fa-trash-alt icon-outline-danger"
        />
      </div>)
    }
    return null;
  }

  const getSelectClass = (errorIdx, name) => {
    if (errorIdx) {
      return errorIdx[name] ? `custom-react-select custom-react-select--error` : "custom-react-select";
    }
    return "custom-react-select";
  };

  const getLabelClass = (values, name) => {
    return `label${values[name] ? " label--valuable" : ""}`
  }

  const genderForm = (values, i) => {
    return (
      values.status !== 2 &&
      (<Card key={i}>
        <CardBody>
          <div className="title-form d-flex justify-content-between">
            <h6 className="mb-5 font-size-14">Phân quyền {i + 1}</h6>
            {initData.filter(x => x.status !== 2).length > 1 && <DeleteIcon i={i} />}
          </div>
          <Row className="align-items-center">
            <Col lg={6} className="mb-4">
              <div className="select-container mb-2">
                <Select
                  ref={el => permissionRef.current[i] = el}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() =>
                    "Không có lựa chọn"
                  }
                  placeholder="Chọn loại phê duyệt"
                  name="approveType"
                  components={{
                    Placeholder,
                    DropdownIndicator
                  }}
                  value={values.approveTypes || null}
                  options={addOptions(approveTypeValues)}
                  onChange={(value) => handleChangeSelect("approveType", value, i)}
                  onBlur={() => handleErrors("approveType", values.approveType, i)}
                  className={getSelectClass(errors[i], "approveType")}
                  classNamePrefix="select2-selection"
                  isClearable
                  isDisabled={disabled}
                />
                <Label
                  for="approveType"
                  className={getLabelClass(values, "approveType")}
                  onClick={() => permissionRef.current[i].focus()}
                >
                  Loại phê duyệt {" "}
                  <span className="color-red">
                    *
                  </span>
                </Label>
                {errors[i]?.approveType && (
                  <>
                    <FormFeedback>
                      {errors[i]?.approveType}
                    </FormFeedback>
                    <div className="mb-negative-40"></div>
                  </>
                )}
              </div>
            </Col>
            <Col lg={6} className="mb-4">
              <div className="select-container mb-2">
                <Select
                  ref={el => titleRef.current[i] = el}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() =>
                    "Không có lựa chọn"
                  }
                  placeholder="Chọn chức danh"
                  name="title"
                  value={values.title || null}
                  components={{
                    Placeholder,
                    DropdownIndicator
                  }}
                  options={getOptions(parents)}
                  onChange={(value) => { handleChangeSelect("title", value, i); handlchildrenTitle(value, i) }}
                  onBlur={() => handleErrors("title", values.title, i)}
                  className={getSelectClass(errors[i], "title")}
                  classNamePrefix="select2-selection"
                  isClearable
                  isDisabled={disabled}
                />
                <Label
                  for="title"
                  className={getLabelClass(values, "title")}
                  onClick={() => titleRef.current[i].focus()}
                >
                  Chức danh{" "}
                  <span className="color-red">
                    *
                  </span>
                </Label>
                {errors[i]?.title && (
                  <>
                    <FormFeedback>
                      {errors[i]?.title}
                    </FormFeedback>
                    <div className="mb-negative-40"></div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-4">
              <div className="select-container mb-2">
                <Select
                  ref={el => permisionTitlesRef.current[i] = el}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  isMulti
                  noOptionsMessage={() =>
                    "Không có lựa chọn"
                  }
                  placeholder="Chọn chức danh chi tiết"
                  components={{
                    Option,
                    Placeholder,
                    DropdownIndicator
                  }}
                  name="permisionTitles"
                  value={values.permisionTitles || []}
                  options={getOptions(childrens[i])}
                  onChange={(value) => handleChangeSelect("permisionTitles", value, i)}
                  onBlur={() => handleErrors("permisionTitles", values.permisionTitles, i)}
                  className={getSelectClass(errors[i], "permisionTitles")}
                  classNamePrefix="select2-selection"
                  isClearable={false}
                  isDisabled={disabled}
                />
                <Label
                  for="permisionTitles"
                  className={`label${values.permisionTitles?.length > 0
                    ? " label--valuable"
                    : ""
                    }`}
                  onClick={() => permisionTitlesRef.current[i].focus()}
                >
                  Chức danh chi tiết
                </Label>
                {errors[i]?.permisionTitles && (
                  <>
                    <FormFeedback>
                      {errors[i]?.permisionTitles}
                    </FormFeedback>
                    <div className="mb-negative-40"></div>
                  </>
                )}
              </div>
            </Col>
            <Col lg={6} className="mb-4">
              <div className="input-container mb-2">
                <Input
                  innerRef={el => departmentRequiresChangeRef.current[i] = el}
                  maxLength={50}
                  className={'text-input'}
                  type="text"
                  name="departmentRequiresChange"
                  value={values.departmentRequiresChange || ""}
                  onChange={(e) => handleChange(e, i)}
                  disabled={disabled}
                />
                <label htmlFor="departmentRequiresChange" className="label" onClick={() => departmentRequiresChangeRef.current[i].focus()}>
                  Bộ phận yêu cầu điều chỉnh
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-4">
              <div className="input-container mb-2">
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={values.approveContent || ""}
                  onReady={(editor) => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeCKEditor(data, i);
                  }}
                  disabled={disabled}
                />
                <label
                  htmlFor="approveContent"
                  className="label label--valuable"
                >
                  Nội dung phê duyệt
                </label>
              </div>
            </Col>
            <Col lg={12} className="mb-4">
              <div className="input-container mb-2">
                <Input
                  maxLength={200}
                  className={'text-input'}
                  type="textarea"
                  name="comment"
                  value={values.comment || ""}
                  onChange={(e) => handleChange(e, i)}
                  disabled={disabled}
                />
                <label htmlFor="comment" className="label">
                  Ghi chú
                </label>
              </div>
            </Col>
            <Col lg={12} className="mb-4">
              <div className="input-container mb-2">
                <Input
                  innerRef={el => changeReasonRef.current[i] = el}
                  maxLength={200}
                  className={'text-input'}
                  type="text"
                  name="changeReason"
                  value={values.changeReason || ""}
                  onChange={(e) => handleChange(e, i)}
                  disabled={disabled}
                />
                <label htmlFor="changeReason" className="label" onClick={() => changeReasonRef.current[i].focus()}>
                  Lý do điều chỉnh
                </label>
              </div>
            </Col>
            {
              values.approveType === "TD" &&
              <Col lg={12} className="mb-4">
                <div className="input-container mb-2">
                  <Input
                    innerRef={el => appraisalTypeRef.current[i] = el}
                    maxLength={50}
                    className={'text-input'}
                    type="text"
                    name="appraisalType"
                    value={values.appraisalType || ""}
                    onChange={(e) => handleChange(e, i)}
                    disabled={disabled}
                  />
                  <label htmlFor="appraisalType" className="label" onClick={() => appraisalTypeRef.current[i].focus()}>
                    Loại thẩm định
                  </label>
                </div>
              </Col>
            }
          </Row>
        </CardBody>
      </Card>)
    )
  }

  const addMoreForm = () => {
    const multipleData = [...initData];
    const coppyDataDefault = {
      ...defaultValue,
      work: entitywork,
      CnpqTypeId: CnpqType.id,
      WorkId: entitywork?.id
    }
    multipleData.push(coppyDataDefault);
    setData(multipleData)
  }

  const handleClose = (isReload = false) => {
    setData([defaultValue]);
    setOpen(false);
    handleCloseModal(isReload);
  }

  const handleChange = (e, i) => {
    const { name, value } = e.target
    const list = [...initData];
    list[i] = {
      ...initData[i],
      [name]: value
    };
    setData(list)
  }
  const handleChangeCKEditor = (data, i) => {
    const list = [...initData];
    list[i] = {
      ...initData[i],
      approveContent: data
    };
    setData(list)
  }

  const handleChangeSelect = (selector, value, i) => {
    const list = [...initData];
    if (selector === "approveType") {
      list[i] = {
        ...initData[i],
        [selector]: value ? value.value : null,
        approveTypes: value,
        appraisalType: null,
        assessmentType: value ? value.value : null,
      };
    }
    else
      if (selector === "permisionTitles") {
        list[i] = {
          ...initData[i],
          [selector]: value ? value.map(x => ({
            ...x,
            TitleId: x.value
          })) : null,
        };
      }
      else {
        list[i] = {
          ...initData[i],
          [selector]: value
        };
      }
    setData(list);
    handleErrors(selector, value, i);
  }

  const handleErrors = (selector, value, i) => {
    if ((!value || value.length <= 0) && selector !== "permisionTitles") {
      const lstErrors = [...errors];
      if (!lstErrors[i]) {
        lstErrors[i] = {};
      }
      lstErrors[i][selector] = "Vui lòng nhập";
      setErrors(lstErrors);
    }
    else {
      if (errors.length > 0) {
        const lstErrors = [...errors];
        if (lstErrors[i]) {
          delete lstErrors[i][selector];
        }
        setErrors(lstErrors);
      }
    }
  }

  const handleWarning = async (data) => {
    const payload = {
      workId: selected.id,
      typeCNPQId: CnpqType.id,
      titleId: data.map(value => (
        value.title?.id
      ))
    }
    const respone = await dispatch(warning(payload))
    const result = respone.payload as AxiosResponse;
    if (result.data) {
      toast.warning(result.data);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = [...initData];
    const warnings = _.filter(payload, function (x) {
      return !x.warning
    });
    if (warnings.length > 0) {
      handleWarning(payload)
    }
    payload = payload.map((value) => ({
      ...value,
      departmentRequiresChange: value.departmentRequiresChange?.trim(),
      changeReason: value.changeReason?.trim(),
      appraisalType: value.appraisalType?.trim(),
      comment: value.comment?.trim(),
      approveContent: value.approveContent?.trim(),
      status: value.status === 2 ? value.status : value.id ? 1 : 0,
      warning: false,
      CnpqTypeName: CnpqType.name,
      NameCnpq: selected.name,
      createdBy: value.id ? value.createdBy : account.email,
      lastModifiedBy: account.email,
    }))
    const lstErros = getListErrors(errors);
    if (lstErros) {
      return;
    }
    dispatch(updateEntity(payload))
  }

  const deleteForm = (i) => {
    const list = [...initData];
    if (list[i].id) {
      const listUpdateStatus = {
        ...list[i],
        status: 2
      }
      list[i] = listUpdateStatus
    }
    else {
      list.splice(i, 1);
    }
    setData(list);
  }

  return (
    <>
      <Modal isOpen={isOpened}
        toggle={() => {
          handleClose();
        }}
        backdrop={"static"}
        centered
        size="lg">
        <ModalHeader toggle={() => {
          handleClose();
        }}>
          Cập nhật phân quyền
        </ModalHeader>
        <ModalBody>
          <Row xl={12}>
            <article className="description-modal">
              <h6>Tên đầu việc</h6>
              <p className="font-size-14">{selected?.name.replace(/<[^>]+>/g, '')}</p>
            </article>
          </Row>
          <form onSubmit={handleSubmit}>
            {initData && initData.map((values, i) => {
              return (
                genderForm(values, i)
              );
            }
            )}
            <div className="d-flex justify-content-start mt-4">
              <Button type="button" color="add" onClick={addMoreForm} disabled={disabled}>
                <SVG src="content/images/icons/plus-icon.svg" />
                Thêm phân quyền
              </Button>
            </div>
            <div className="d-flex justify-content-end mt-40 gap-12">
              <Button
                disabled={isDisabled}
                color="primary"
                type="submit"
                className="btn-size-md"
              >
                <i className="fas fa-save"></i>
                <span className="btn-name">Lưu</span>
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                }}
                color="secondary"
                className="btn-size-md"
              >
                <i className="fas fa-ban"></i>
                <span className="btn-name">Bỏ qua</span>
              </Button>
            </div>
          </form>

        </ModalBody>
      </Modal>
    </>
  );
};

export default PermisionUpdate;
