export interface IHistory {
  id?: number;
  name?: string | null;
  code?: string | null;
  cnpqType?: string | null;
  approve?: boolean | null;
  version?: number | null;
  permissionId?: number | null;
  workId?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IHistory> = {
  id: null,
  name: null,
  code: null,
  cnpqType: null,
  approve: false,
  version: null,
  permissionId: null,
  workId: null,
  createdDate: null,
  createdBy: null,
  lastModifiedDate: null,
  lastModifiedBy: null
};
