import React from "react";

import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

export const NavDropdown = (props) => (
    <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props["data-cy"]}>
        <DropdownToggle nav caret className="d-flex align-items-center justify-content-between">
            {
                props.isAuthenticated && (
                    <>
                        <img src="content/images/logos/user-default.png" width="40px"/>
                    </>
                )
            }
            <span className="m-2">{props.name}</span>
            {
                props.isAuthenticated && (
                    <>
                        <i className="ri-arrow-right-s-line" style={{textAlign: "right", color: "#999"}}></i>
                    </>
                )
            }
        </DropdownToggle>

        <DropdownMenu end style={props.style}>
            {props.children}
        </DropdownMenu>
    </UncontrolledDropdown>
);
