import React, { useEffect, useState, useRef } from "react";

import MetisMenu from "metismenujs";

import { Link, useLocation } from "react-router-dom";
import { Brand } from "app/shared/layout/header/header-components";
import { AccountMenu } from "app/shared/layout/menus";

export const SidebarContent = (props) => {
    const permissionRef = useRef(null);

    useEffect(() => {
        initMenu()
    }, []);

    const [toggle, setToggle] = useState(false)
    const location = useLocation();


    const handleToggle = () => {
        setToggle(prevState => !prevState)
    }

    const initMenu = () => {

        const mm = new MetisMenu("#side-menu");
        mm.update();

        let matchingMenuItem = null;
        const sideMenu = document.getElementById("side-menu");
        const items: any = sideMenu.querySelectorAll("a");
        for (const value of items) {
            if (location.pathname === value.pathname) {
                matchingMenuItem = value;
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }


    }


    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active");
                    parent3.childNodes[0].classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
        }
    };

    const removeParentMenuItemsActiveClass = () => {
        parentMenuItems.forEach((el) => {
            if (el.classList.contains("active")) {
                el.classList.remove("active")
            }
        })
    };

    const menuItems = document.querySelectorAll("#side-menu > li");
    const parentMenuItems = document.querySelectorAll("#side-menu > li > a")
    menuItems.forEach((li) => {
        li.addEventListener("click", () => {
            removeParentMenuItemsActiveClass();
            menuItems.forEach((el) => {
                el.classList.remove("mm-active")
            })
            li.classList.add("mm-active")
        })
    })

    useEffect(() => {
        if (location.pathname === "/permision") {
            menuItems.forEach((li) => {
                removeParentMenuItemsActiveClass();
                li.classList.remove("mm-active")
            })
            permissionRef.current.classList.add('mm-active')
        }
    }, [location.pathname])

    return (
        <>
            <div id="sidebar-menu">
                <div className="navbar-brand-box d-flex flex-column align-items-center">
                    <Brand />
                </div>
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className={toggle ? 'open mm-active' : ''}>
                        <Link to="#" className="has-arrow waves-effect" onClick={handleToggle}>
                            <i className="ri-file-list-2-line"></i>
                            <span className="ms-2">
                                Danh mục</span>
                        </Link>
                        {toggle && (
                            <ul className="sub-menu">
                                <li><Link to="/cnpq-type">
                                    <i className="fas fa-folder"></i>
                                    <span className="ms-2">Loại CNPQ</span></Link></li>
                                <li><Link to="/titles">
                                    <i className="far fa-address-card">
                                    </i>
                                    <span className="ms-2">Chức danh</span>
                                </Link></li>
                                <li><Link to="/work">
                                    <i className=" ri-briefcase-4-line"></i>
                                    <span className="ms-2">Đầu việc</span></Link></li>
                            </ul>
                        )}
                    </li>
                    <li ref={permissionRef}>
                        <Link to="/permision?page=1&sort=lastModifiedDate,desc" className="waves-effect">
                            <i className=" fas fa-user-friends"></i><span
                                className="badge rounded-pill bg-success float-end"></span>
                            <span className="ms-2">Cẩm nang phân quyền</span>
                        </Link>
                    </li>


                    <li>
                        <Link to="/history" className="waves-effect">
                            <i className="fas fa-list"></i>
                            <span className="ms-2">Lịch sử hệ thống</span>
                        </Link>
                    </li>
                    {props.isAdmin &&
                        <li>
                            <Link to="/admin/user-management" className="waves-effect">
                                <i className="fas fa-users"></i>
                                <span className="ms-2">Quản lý người dùng</span>
                            </Link>
                        </li>
                    }
                    <AccountMenu isAuthenticated={props.isAuthenticated} />
                    {/* Hide admin menu */}
                    {/* <AdminMenu
                        showOpenAPI={props.isOpenAPIEnabled}
                        showDatabase={!props.isInProduction}
                    /> */}
                </ul>
            </div>

        </>
    );
};

export default SidebarContent;
