import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { bindActionCreators } from "redux";

import getStore from "app/config/store";
import setupAxiosInterceptors from "app/config/axios-interceptors";
import { clearAuthentication } from "app/shared/reducers/authentication";
import ErrorBoundary from "app/shared/error/error-boundary";
import AppComponent from "app/app";
import { loadIcons } from "app/config/icon-loader";

const store = getStore();

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() =>
  actions.clearAuthentication("login.error.unauthorized")
);

loadIcons().then(() => render(AppComponent));

const rootEl = document.getElementById("root");

const render = (Component) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <div>
          <Component />
        </div>
      </Provider>
    </ErrorBoundary>,
    rootEl
  );


