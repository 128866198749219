import titleLevel from "app/modules/title-level/title-level.reducer";
import cnpqType from "app/modules/cnpq-type/cnpq-type.reducer";
import title from "app/modules/title/title.reducer";
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
import work from "app/modules/work/work.reducer";
import permision from "app/modules/permision/permision.reducer";
import history from "app/modules/history/history.reducer";

const reducers = {
  title,
  titleLevel,
  cnpqType,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  work,
  permision,
  history
};

export default reducers;
