export interface IWork {
  id?: number;
  code?: string;
  name?: string | null;
  activated?: boolean | null;
  description?: string | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  parentTask?: IWork | null;
  cnpqTypes?: any | null;
  nameCNPQ?: string | null;
  titleCode?: string | null;
}

export const defaultValue: Readonly<IWork> = {
  id: null,
  code: '',
  name: '',
  nameCNPQ:'',
  activated: true,
  parentTask: null,
  cnpqTypes: []
};
