import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { Button, ButtonDropdown, Col, Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { getAllTitleLevelWidthoutPagination as getTypeCNPQs } from "../cnpq-type/cnpq-type.reducer"
import { exportFile, fetch, setTypeCnpq } from "./permision.reducer"
import { overridePaginationStateWithQueryParams } from "app/shared/util/entity-utils";
import { getSortState } from "react-jhipster";
import { ITEMS_PER_PAGE, SORT } from "app/shared/util/pagination.constants";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { ApproveType } from "app/shared/model/enumerations/approve-type.model";
import PermisionUpdate from "app/modules/permision/permision-update";
import { ChevronDownLight } from "app/assets/images/icons";
import PermisionApproveDialog from "./permision-approve-dialog";
import { AxiosResponse } from "axios";
import * as FileSaver from "file-saver";
import { b64toBlob } from "app/shared/util/base64-bolb-utils";
import { displayDateTimeYYYYMMDDHHMMSS } from "app/shared/util/date-utils";
import { toast } from "react-toastify";
import TableRemotePagination from "app/shared/components/table/table-remote-pagination";
import SVG from "react-inlinesvg";
import { getAllTitleWidthoutPagination as getTitles } from "app/modules/title/title.reducer";
import { getOptions } from "app/shared/util/select-options-utils";
import { LoadingSpinner, LoadingSpinnerCustom } from "app/shared/util/loading-spinner";
import { cleanString } from "app/shared/util/common-utils";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownLight />
    </components.DropdownIndicator>
  );
};

export const Permision = (props: RouteComponentProps<{ url: string }>) => {

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const [isOpenedModalUpdate, setIsOpenedModalUpdate] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [isApprove, setApprove] = useState(0);
  const [cnpqTypes, setCnpqTypes] = useState([]);
  const [openApprove, setOpenApprove] = useState(false);
  const [isOpenExport, setOpenExport] = useState(false);
  const [cnpqTypeList, setCnpqTypeList] = useState(null);
  const permisionList = useAppSelector((state) => state.permision.entities);
  const loading = useAppSelector((state) => state.permision.loading);
  const [initData, setInitData] = useState([]);
  const [titles, setTitles] = useState([]);
  const [toggle, setToggle] = useState(false);
  const totalItems = useAppSelector((state) => state.permision.totalItems);
  const selectedCnpqType = useAppSelector((state) => state.permision.selectedTypeCnpq);
  const [isDefaultPage, setIsDefaultPage] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(null);

  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(
      getSortState(props.location, ITEMS_PER_PAGE, "lastModifiedDate", "desc"),
      props.location.search
    )
  );

  const [innitSearch, setInnitSearch] = useState({
    Name: "",
    Trustee: null,
    Code: "",
    TypeCNPQ: null,
    approveType: null,
    Order: "",
    Content: ""
  });

  // const onSort = (field, order) => {
  //   setPagination({
  //     ...pagination,
  //     sort: field,
  //     order
  //   })
  //   const endURL = `?page=${pagination.activePage}&sort=${field},${order}`;
  //   if (props.location.search !== endURL) {
  //     props.history.push(`${props.location.pathname}${endURL}`);
  //   }
  // }

  const headerStyleFuc = (cell, row, rowIndex) => {
    switch (cell.dataField) {
      case "stt":
        return { maxWidth: "100px" };
      case "code":
        return { maxWidth: "100px" };
      case "name":
        return { width: "20%" };
      case "changeReason":
        return { width: "20%" };
      case "departmentRequiresChange":
        return { width: "20%" };
      case "approveContent":
        return { width: "15%" };
      case "comment":
        return { width: "15%" };
      case "approveType":
        return { maxWidth: "120px" };
      case "permisionTitles":
        return { width: "15%" };
      default:
        return
    }
  };

  const actionFormatter = (cell, row, rowIndex) => {
    return <>
      {!row.deleteFlag &&
        <div className="btn-group flex-btn-group-container d-flex gap-3 justify-content-center align-items-center">
          <div
            onClick={() => {
              handleModalUpdate(row)
            }}
            className="hand"
          >
            <i
              className="fas fa-pencil-alt icon-outline-success"
            />
          </div>
        </div>
      }
    </>
  }

  const colorSTT = (cell, row, rowIndex) => {
    let result = `<span>${(paginationOption.sizePerPage * (isDefaultPage ? 0 : pagination.activePage - 1)) + rowIndex + 1}</span>`
    if (row.deleteFlag === true) {
      result = `<span class="text-danger text-decoration-line-through">${(paginationOption.sizePerPage * (isDefaultPage ? 0 : pagination.activePage - 1)) + rowIndex + 1}</span>`
    }
    return <p className="row-span-p" key={row.id} dangerouslySetInnerHTML={{ __html: result }}></p>;
  }

  const formatWork = (cell, row, rowIndex, dataFieldHTML, dataField) => {
    const fieldDisplay = row.history ? row.history[dataField] : null;
    return handleDisplayColorWork(row)
  }

  const formatCodeNumber = (cell, row, rowIndex, dataField) => {
    const fieldDisplay = row.history ? row.history[dataField] : null;
    return handleDisplayWithColor(row, dataField)
  }

  const formatPermision = (cell, row, rowIndex, dataField, subject) => {
    const histories = mapHistory(row);
    const permissions = row.permissions ? row.permissions : null;
    return renderChildrens(permissions, subject, dataField, histories, row)
  }

  const mapHistory = (row: any) => {
    if (row.history && row.history.historyPermissions) return row.history.historyPermissions;
    return null;
  }

  const columns = [
    {
      dataField: "renderSTT",
      text: "STT",
      formatter: (cell, row, rowIndex) => colorSTT(cell, row, rowIndex),
      headerStyle: headerStyleFuc,
      style: { textAlign: "center" },
    },
    {
      dataField: "stt",
      text: "Mã số",
      formatter: (cell, row, rowIndex) => formatCodeNumber(cell, row, rowIndex, "stt"),
      headerStyle: headerStyleFuc,
      style: { textAlign: "left", minWidth: "100px" },
      // sort: true,
      // onSort
    },
    {
      dataField: "name",
      text: "Đầu việc",
      formatter: (cell, row, rowIndex) => formatWork(cell, row, rowIndex, "nameDiffPrettyHTML", "name"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "400px" },
      // sort: true,
      // onSort
    },
    {
      dataField: "changeReason",
      text: "Lý do điều chỉnh",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "changeReason", "Lý do điều chỉnh"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "400px" },
      // onSort
    },
    {
      dataField: "departmentRequiresChange",
      text: "Bộ phận điều chỉnh",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "departmentRequiresChange", "Bộ phận yêu cầu điều chỉnh"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "300px" },
      // onSort
    },
    {
      dataField: "approveContent",
      text: "Nội dung phê duyệt",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "approveContent", "Nội dung phê duyệt"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "400px" },
      // onSort
    },
    {
      dataField: "comment",
      text: "Ghi chú",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "comment", "Ghi chú"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "300px" },
      // onSort
    },
    {
      dataField: "approveType",
      text: "Loại phê duyệt",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "approveType", "Loại phê duyệt"),
      headerStyle: headerStyleFuc,
      // onSort
    },
    {
      dataField: "",
      text: "CBLĐ thực hiện",
      formatter: (cell, row, rowIndex) => formatPermision(cell, row, rowIndex, "permisionTitles", "Chức danh chi tiết"),
      headerStyle: headerStyleFuc,
      style: { minWidth: "300px" },
    },
    {
      dataField: "action",
      text: "",
      headerAlign: (column, colIndex) => "center",
      formatter: actionFormatter,
      // headerClasses: "freeze-panes-last-column",
      classes: "freeze-panes-last-column",
      style: { textAlign: "right" },
    },
  ];

  const paginationOption = {
    custom: true,
    page: 1,
    totalSize: totalItems,
    paginationSize: 5,
    alwaysShowAllBtns: true,
    sizePerPage: ITEMS_PER_PAGE,
  };

  const optionsSelectTypeApprove = Object.entries(ApproveType).filter(([key]) => key !== "No").map(([key, value]) => ({ value: key, label: value }));

  useEffect(() => {
    if (isAuthenticated && pathname === "/") {
      props.history.push("/permision?page=1&sort=lastModifiedDate,desc");
    }
  }, [isAuthenticated])

  useEffect(() => {
    handlOnload();
  }, []);

  useEffect(() => {
    if (selectedCnpqType) {
      setInnitSearch({ ...innitSearch, TypeCNPQ: selectedCnpqType.value })
      const filter = {
        ...innitSearch,
        TypeCNPQ: selectedCnpqType.value
      }
      fetchDataFromProps(filter)
    }
    else if (cnpqTypes.length > 0) {
      dispatch(setTypeCnpq(cnpqTypes[0]));
      setInnitSearch({ ...innitSearch, TypeCNPQ: cnpqTypes[0].value })
      const filter = {
        ...innitSearch,
        TypeCNPQ: cnpqTypes[0].value
      }
      fetchDataFromProps(filter)
    }
  }, [cnpqTypes]);

  useEffect(() => {
    if (cnpqTypeList && cnpqTypeList.length > 0) {
      const CnpqTypes = cnpqTypeList.map(x => ({
        ...x,
        value: x.id,
        label: x.name
      }))
      setCnpqTypes(CnpqTypes);
    }
  }, [cnpqTypeList]);

  useEffect(() => {
    if (permisionList && permisionList.length > 0) {
      let newWorkList = [...permisionList]
      newWorkList = _.filter(newWorkList, function (data) {
        return data.deleteFlag !== true || data.permissions.length > 0
      })
      newWorkList = newWorkList.slice(0, paginationOption.sizePerPage)
      setInitData(newWorkList);
    }
    else {
      setInitData([]);
    }
    console.log('permissionList:', permisionList)
    handleIsApprove(permisionList);
  }, [permisionList]);

  useEffect(() => {
    if (innitSearch && innitSearch.TypeCNPQ) {
      fetchDataFromProps(currentSearch ? currentSearch : innitSearch);
    }
  }, [pagination.activePage, pagination.order, pagination.sort]);

  const handlOnload = async () => {
    await dispatch(getTitles()).then(result => {
      if (result && result.payload) {
        const data: any = result.payload
        setTitles(data)
      }
    });
    const respone = await dispatch(getTypeCNPQs());
    const result = respone.payload as AxiosResponse
    if (result && result.data) {
      setCnpqTypeList(result.data);
    }
  }

  const handleIsApprove = (array) => {
    if (array && array.length > 0) {
      if (array.length > 0) {
        if (array.some(x => x.permissions.length > 0 && x.deleteFlag === true)) {
          setApprove(0);
          localStorage.setItem('isApprove', '0')
          return 
        }
        else {
          const arrayNoDelete = _.filter(array, function (o) {
            return o.deleteFlag !== true
          })
          if (arrayNoDelete.length > 0) {
            // const dataApprove = arrayNoDelete.every(x => x.permissions.length > 0 && x.permissions.every(y => y.status === 3))
            const dataApprove = arrayNoDelete.every(x => {
              if(x.permissions.length === 0) {
                localStorage.setItem('isApprove', '0')
                return setApprove(0)
              }
              else {
                return x.permissions.every(y => y.status === 3)
              }
            })
            if (dataApprove) {
              localStorage.setItem('isApprove', '3')
              setApprove(3);
              return
            }
            else {
              // const dataAccept = arrayNoDelete.every(x => x.permissions.length > 0 && x.permissions.every(y => y.status === 4))
              // const dataAccept = arrayNoDelete.every(x => x.permissions.every(y => y.status === 4))
              const dataAccept = arrayNoDelete.every(x => {
                if(x.permissions.length === 0) {
                  localStorage.setItem('isApprove', '0')
                  return setApprove(0)
                }
                else {
                  return x.permissions.every(y => y.status === 4)
                }
              })
              if (dataAccept) {
                localStorage.setItem('isApprove', '4')
                setApprove(4);
                return 
              }
              else {
                localStorage.setItem('isApprove', '0')
                setApprove(0);
              }
            }
          }
        }
      }
    }
    else {
      localStorage.setItem('isApprove', '0')
      setApprove(0);
    }
  }

  const fetchDataFromProps = (search?: any) => {
    dispatch(
      fetch({
        page: pagination.activePage - 1,
        size: pagination.itemsPerPage,
        sort: `${pagination.sort},${pagination.order}`,
        filter: JSON.stringify(search)
      })
    );
    const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get("page");
    const sortParam = params.get(SORT);
    if (page && sortParam) {
      const sortSplit = sortParam.split(",");
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const handleCloseModal = (reload) => {
    if (reload) {
      const filter = {
        ...currentSearch,
        TypeCNPQ: selectedCnpqType.id
      }
      fetchDataFromProps(filter)
    }
    setIsOpenedModalUpdate(false);
    setSelectedItem(null);
  }

  const handleCloseModalApprove = (value) => {
    if (value) {
      const filter = {
        ...currentSearch,
        TypeCNPQ: selectedCnpqType.id
      }
      fetchDataFromProps(filter)
    }
    setOpenApprove(false);
    setSelectedItem(null);
  }

  const handleModalUpdate = (item?: any) => {
    if (item.id) {
      setSelectedItem(item);
    }
    setIsOpenedModalUpdate(true);
  };

  const handleModalApprove = () => {
    if (initData.length > 0) {
      setOpenApprove(true)
    }
    else {
      toast.warning(`CNPQ ${selectedCnpqType.name} chưa có đầu việc. Vui lòng kiểm tra lại`)
    }
  };
  // action change value search

  const handleChangeInput = (e) => {
    const { value, name } = e.target;
    setInnitSearch({ ...innitSearch, [name]: value })
  }

  const handleSelect = (field, item) => {
    setInnitSearch({ ...innitSearch, [field]: item ? item.value : null })
  }

  const handleSelectTypeCNPQ = (item) => {
    dispatch(setTypeCnpq(item));
    const type = item ? item.value : null;
    setInnitSearch({ ...innitSearch, TypeCNPQ: type })
    const filter = {
      ...innitSearch,
      TypeCNPQ: type
    }
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        activePage: 1
      }
    });
    fetchDataFromProps(filter)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsDefaultPage(true)
    const searchBody = {
      ...innitSearch,
      Name: innitSearch.Name?.trim() || null,
      Code: innitSearch.Code?.trim() || null,
      Order: innitSearch.Order?.trim() || null,
      Content: innitSearch.Content?.trim() || null
    }
    setCurrentSearch(searchBody)
    dispatch(
      fetch({
        page: 0,
        size: pagination.itemsPerPage,
        sort: `${pagination.sort},${pagination.order}`,
        filter: JSON.stringify(searchBody)
      })
    );
    const endURL = `?page=1&sort=${pagination.sort},${pagination.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  }

  const handleDisplayColorWork = (work) => {
    if (work?.workStatus === "Approved" || work?.workStatus === "Created" ||  work?.workStatus === "Modified") {
      if(isApprove === 3) {
        const stringWithColorGreen = cleanString(work?.nameDiffPrettyHTML)
        return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: stringWithColorGreen }}></p>
      }
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: work?.nameDiffPrettyHTML }}></p>;
    }
    if (work?.workStatus === "Deleted") {
      return <p className="row-span-p text-danger text-decoration-line-through" key={work.id} dangerouslySetInnerHTML={{ __html: work?.name }}></p>;
    }
    else {
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: work?.name }}></p>;
    }
  }


  const handleDisplayWithColor = (work, field) => {
    let newDisplay = work[field] ? work[field] : "";
    if (work?.workStatus === "Created") {
      newDisplay = `<span class="text-success-green">${work[field]}</span>`
    }
    if (work?.workStatus === "Deleted") {
      newDisplay = `<span class="text-danger text-decoration-line-through">${work[field]}</span>`
    }
    // else {
    //   newDisplay = `<span class="row-span-p">${work[field]}</span>`
    // }
    // const historyValues = fielDisplay?.split(" ");
    // const newValues = work[field]?.split(" ");
    // if (work?.permissions?.every(x => x.status === 1)) {
    //   let count = 1;
    //   for (let i = 0; i < newValues?.length; i++) {
    //     const indexValue = _.findIndex(historyValues, function (history) {
    //       return history === newValues[i]
    //     })
    //     if (historyValues && historyValues.length > 0) {
    //       if (indexValue < 0) {
    //         const value = `<span class="text-success-green">${newValues[i]}</span>`
    //         newArray.push(value);
    //       }
    //       else {
    //         newArray.push(newValues[i]);
    //         count++;
    //       }
    //     }
    //     else {
    //       newArray.push(newValues[i]);
    //       count++;
    //     }
    //   }
    //   let dem = 1;
    //   for (let j = 0; j < historyValues?.length; j++) {
    //     const indexValue = _.findIndex(newValues, function (newValue) {
    //       return newValue === historyValues[j]
    //     })
    //     if (indexValue < 0) {
    //       const value = `<span class="text-danger text-decoration-line-through">${historyValues[j]}</span>`
    //       if (!newArray[j]) {
    //         newArray.push(value);
    //         dem++;
    //       }
    //       else {
    //         AddDataWithIndex(newArray, newArray.length, ((j + (count - historyValues.length > 0 ? count - historyValues.length : historyValues.length - count) + dem) - 1), value);
    //         dem++;
    //       }
    //     }
    //   }
    // }
    // else if (work?.permissions?.every(x => x.status === 3)) {
    //   for (let i = 0; i < newValues?.length; i++) {
    //     const data = historyValues?.filter(x => x === newValues[i])
    //     if (data && data.length <= 0) {
    //       const value = `<span class="text-success-green">${newValues[i]}</span>`
    //       newArray.push(value);
    //     }
    //     else {
    //       newArray.push(newValues[i])
    //     }
    //   }
    // }
    // else if (work?.permissions?.every(x => x.status === 2)) {
    //   for (let j = 0; j < newValues?.length; j++) {
    //     if (work.permissions.length > 0) {
    //       const value = `<span class="text-danger text-decoration-line-through">${newValues[j]}</span>`
    //       newArray.push(value);
    //     }
    //     else {
    //       newArray.push(newValues[j]);
    //     }
    //   }
    // }
    // else {
    //   for (let i = 0; i < newValues?.length; i++) {
    //     newArray.push(newValues[i])
    //   }
    // }
    // const result = newArray.join(" ");
    return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: newDisplay }}></p>;
  }

  const AddDataWithIndex = (array: any, length: number, indexAddToArray: number, value: string) =>// ham them phan tu
  {
    if (indexAddToArray <= 0)// Them vao dau mang
    {
      indexAddToArray = 0;
    } else if (indexAddToArray >= length)// Them vao cuoi mang
    {
      indexAddToArray = length;
    }
    for (let i = length; i > indexAddToArray; i--)// dich chuyen mang de tao o trong truoc khi them
    {
      array[i] = array[i - 1];
    }
    array[indexAddToArray] = value;// chen gia tri can them y tai x
    length++;// tang so phan tu cua mang
  }

  const handleDisplayPermisionWithColor = (work, fieldDisplay) => {
    if (work.status === 1) {
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: fieldDisplay.diffPrettyHtml }}></p>
    }
    else if (work.status === 3) {
      const stringWithColorGreen = cleanString(fieldDisplay.diffPrettyHtml)
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: stringWithColorGreen }}></p>
    }
    else if (work.status === 0) {
      return <p className="text-success-green" key={work.id} dangerouslySetInnerHTML={{ __html: fieldDisplay.newValue }}></p>
    }
    else if (work.status === 2) {
      return <p className="row-span-p text-danger text-decoration-line-through" key={work.id} dangerouslySetInnerHTML={{ __html: fieldDisplay.newValue }}></p>
    }
    else {
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: fieldDisplay.newValue }}></p>
    }
  }

  const handleDisplayArray = (subject, work, field, histories) => {
    if (subject === "Chức danh chi tiết") {
      const titleDisplays = work[field]?.map(x => {
        return (
          x.name
        );
      })
      const result = titleDisplays.join(", ")
      let value = ""
      if (work.status === 2) {
        value = `<span class="text-danger text-decoration-line-through">${result}</span>`
      }
      else if (work.status !== 4 && histories) {
        value = `<span class="text-success-green">${result}</span>`
      }
      else {
        value = result
      }
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: value }}></p>;
    }
    else {
      let value = ""
      if (work.status === 2) {
        value = `<span class="text-danger text-decoration-line-through">${work[field] ? work[field] : ""}</span>`
      }
      else if (work.status !== 4 && histories) {
        value = `<span class="text-success-green">${work[field] ? work[field] : ""}</span>`
      }
      else {
        value = work[field] ? work[field] : ""
      }
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: value }}></p>;
    }
  }

  const setValueFieldAndSubject = (field: string, arrayHistory: any, subject) => {
    if (field === "permisionTitles" && (!arrayHistory || arrayHistory.length <= 0)) {
      return {
        value: "titleName",
        key: "Chức danh"
      }
    }
    else {
      return {
        value: field,
        key: subject
      }
    }
  }

  const handleValueNotHistory = (work, newField, workStatus) => {
    let value = ""
    if (newField === "permisionTitles") {
      const titleDisplays = work[newField]?.map(x => {
        return (
          x.name
        );
      })
      const result = titleDisplays.join(", ")
      if (work.status === 2) {
        value = `<span class="text-danger text-decoration-line-through">${result}</span>`
      }
      if (work.status === 0) {
        value = `<span class="text-success-green">${result}</span>`
      }
      if (workStatus === "Created" && work.status === 1 || work.status === 3) {
        value = `<span class="text-success-green">${result}</span>`
      }
      // else {
      //   value = result
      // }
      return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: value !== "" ? value : result }}></p>;
    }
    if (work.status === 2) {
      value = `<span class="text-danger text-decoration-line-through">${work[newField] ? work[newField] : ""}</span>`
    }
    if (work.status === 0) {
      value = `<span class="text-success-green">${work[newField] ? work[newField] : ""}</span>`
    }
    if (workStatus === "Created" && work.status === 1 || work.status === 3) {
      value = `<span class="text-success-green">${work[newField] ? work[newField] : ""}</span>`
    }
    // else {
    //   value = work[newField] ? work[newField] : ""
    // }

    return <p className="row-span-p" key={work.id} dangerouslySetInnerHTML={{ __html: value !== "" ? value : work[newField] ? work[newField] : "" }}></p>;
  }

  const renderChildrens = (array, subject, field, histories, row) => {
    // array = row.permisssion, histories = row.history.historyPermissions
    // if (array ) {
    if(array && row.workStatus !== "Deleted") {
      return array.map((work) => {
        const data = setValueFieldAndSubject(field, work.permisionTitles, subject);
        const newField = data.value
        const newSubject = data.key
        if (work[newField] !== "No" && work[newField] !== "default") {
          if (histories?.length > 0) {
            const fieldDisplay = histories.filter(x => x.permissionId === work.id && x.subject === newSubject)[0];
            if (fieldDisplay != null) {
              return (
                handleDisplayPermisionWithColor(work, fieldDisplay)
              );
            }
            return (
              handleDisplayArray(newSubject, work, newField, histories)
            );
          }
          else {
            return (
              handleValueNotHistory(work, newField, row.workStatus)
            );
          }
        }
      })
    }

    // hiển thị đầu việc, phân quyền chữ đỏ gạch khi xóa đầu việc ở màn đầu việc
    if(array && row.workStatus === "Deleted") {
      return array.map((work) => {
          const data = setValueFieldAndSubject(field, work.permisionTitles, subject);
          const newField = data.value
          const newSubject = data.key
          if (work[newField] !== "No" && work[newField] !== "default") {
            if (newSubject === "Chức danh chi tiết") {
              const detailTitle = work[field]?.map(x => {
                return (
                  x.name
                );
              })
              const display = detailTitle.join(", ")
              return <p className="row-span-p text-danger text-decoration-line-through" key={work.id} dangerouslySetInnerHTML={{ __html: display }}></p>;
            }
            return <p className="row-span-p text-danger text-decoration-line-through" key={work.id} dangerouslySetInnerHTML={{ __html: work[newField] }}></p>;
          }
      })
    }
  }

  const handleBeforeExport = () => {
    if (initData.length > 0) {
      let value = null;
      const dataApprove = initData.every(x => x.permissions.length > 0 && x.permissions.every(y => y.status === 3))
      const dataAccept = initData.every(x => x.permissions.length > 0 && x.permissions.every(y => y.status === 4))
      if (dataApprove) {
        value = 3
      }
      else if (dataAccept) {
        value = 4
      }
      else {
        toast.warning(`Bạn vui lòng Phê duyệt cẩm nang phân quyền trước khi thực hiện In bản đã duyệt.`)
        return;
      }

      handleExport(value)
    }
    else {
      toast.warning(`CNPQ ${selectedCnpqType.name} chưa có đầu việc. Vui lòng kiểm tra lại`)
      return;
    }
  }

  const handleExport = async (value) => {
    let version = "Bản chưa duyệt";
    const payload = {
      type: value,
      cnpqTypeId: selectedCnpqType.id
    }
    const response = await dispatch(exportFile(payload));
    const result = response.payload as AxiosResponse
    if (result && result.data) {
      const blob = b64toBlob(result.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', null);
      if (value === 3) {
        version = "Bản đã duyệt"
      }
      else if (value === 4) {
        version = "Bản xác nhận"
      }
      else if (value === 5) {
        version = "Bản cảnh báo"
      }
      const fileName = `${selectedCnpqType.name}_${version}_${displayDateTimeYYYYMMDDHHMMSS()}.xlsx`;
      FileSaver.saveAs(blob, fileName);
    };
  }
  const handleToggle = () => {
    setToggle(!toggle);
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setIsDefaultPage(false)
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        activePage: page
      }
    });
  }

  return (
    <>
      {loading ? <LoadingSpinnerCustom /> : null}
      <Container fluid>
        <article className="title-table">
          <div className="title-container">
            <h2
              id="titles-management-page-heading"
              data-cy="titlesManagementPageHeading"
            >
              Cẩm nang phân quyền
            </h2>
            {cnpqTypes.length > 0 &&
              <div className="select-container">
                <Select
                  noOptionsMessage={() =>
                    "Không có lựa chọn"
                  }
                  inputId="typeCnpq"
                  name="TypeCNPQ"
                  onChange={(value) => {
                    handleSelectTypeCNPQ(value);
                  }}
                  placeholder="Loại CNPQ"
                  components={{
                    Placeholder,
                    DropdownIndicator
                  }}
                  defaultValue={selectedCnpqType ? selectedCnpqType : cnpqTypes[0]}
                  classNamePrefix="select2-selection"
                  options={cnpqTypes}
                  className="custom-react-select "
                />
                <Label htmlFor="typeCnpq" className={`label${innitSearch.TypeCNPQ
                  ? " label--valuable"
                  : " "
                  }`}>
                  Loại CNPQ</Label>
              </div>
            }
            {(isApprove === 3 || isApprove === 4) &&
              <div className="lh-1">
                <i className="fas fa-check-circle icon-check" />
              </div>
            }
          </div>
          <div className="btn-container">
            <Button
              color="primary"
              type="button"
              onClick={() => { handleExport(5) }}
              className="btn-size-lg"
            >
              <span className="btn-name fw-semi-bold">
                Báo cáo cảnh báo
              </span>
            </Button>
            <ButtonDropdown
              isOpen={isOpenExport}
              toggle={() =>
                setOpenExport(!isOpenExport)
              }
            >
              <DropdownToggle caret
                className="btn-size-lg"
                color="primary"
                type="button">
                <i className="fa fa-print" /> In <i className="fa fa-caret-down"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => { handleExport(0) }}><i className="mdi mdi-alert-circle-outline" /> In bản chưa duyệt</DropdownItem>
                <DropdownItem onClick={() => { handleBeforeExport() }}><i className="mdi mdi-check-circle-outline" /> In bản đã duyệt</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <Button
              color="primary"
              type="button"
              onClick={handleModalApprove}
              className="btn-size-lg"
            >
              <SVG src="content/images/icons/file-check.svg" />
              <span className="btn-name fw-semi-bold">
                {isApprove === 4 || isApprove === 0 ? "Phê duyệt" : "Xác nhận"}
              </span>
            </Button>
          </div>
        </article>
        <form onSubmit={handleSubmit}>
          <Row className="w-100 mb-20 filter mx-0">
            <Col xs="10" md="11">
              <Row>
                <Col md={3}>
                  <div className="input-container">
                    <Input id="name" maxLength={500} type="text" name="Name" value={innitSearch.Name} className={'text-input'} onChange={handleChangeInput} />
                    <label htmlFor="name" className="label">Tên đầu việc</label>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="input-container">
                    <Input id="order" type="text" name="Order" maxLength={50} value={innitSearch.Order} className={'text-input'} onChange={handleChangeInput} />
                    <label htmlFor="order" className="label">Mã số</label>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="select-container">
                    <Select
                      noOptionsMessage={() =>
                        "Không có lựa chọn"
                      }
                      inputId="approveType"
                      name="approveType"
                      onChange={(value) => handleSelect("approveType", value)}
                      placeholder="Loại phê duyệt"
                      classNamePrefix="select2-selection"
                      options={optionsSelectTypeApprove}
                      escapeClearsValue
                      components={{
                        Placeholder,
                        DropdownIndicator
                      }}
                      isClearable
                      className="custom-react-select "
                    />
                    <Label htmlFor="approveType" className={`label${innitSearch.approveType
                      ? " label--valuable"
                      : " "
                      }`}>
                      Loại phê duyệt</Label>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="select-container">
                    <Select
                      noOptionsMessage={() =>
                        "Không có lựa chọn"
                      }
                      inputId="trustee"
                      name="Trustee"
                      onChange={(value) => handleSelect("Trustee", value)}
                      placeholder="CBLĐ thực hiện"
                      escapeClearsValue
                      isClearable
                      components={{
                        Placeholder,
                        DropdownIndicator
                      }}
                      classNamePrefix="select2-selection"
                      options={getOptions(titles)}
                      className="custom-react-select "
                    />
                    <Label htmlFor="trustee" className={`label${innitSearch.Trustee != null
                      ? " label--valuable"
                      : " "
                      }`}>
                      CBLĐ thực hiện</Label>
                  </div>
                </Col>

                {toggle &&
                  <Col md={3}>
                    <div className="input-container">
                      <Input id="content" type="text" name="Content" maxLength={50} value={innitSearch.Content} className={'text-input'} onChange={handleChangeInput} />
                      <label htmlFor="content" className="label">Nội dung phê duyệt</label>
                    </div>
                  </Col>
                }
              </Row>
            </Col>
            <Col xs="2" md="1" className="d-flex justify-content-end">
              <i onClick={handleToggle} className={`mdi ${toggle ? 'mdi-chevron-up' : 'mdi-chevron-down'} icon-toggle`}></i>
            </Col>
            {toggle && (
              <>
                <hr />
                <div className="w-100 d-flex justify-content-end mt-0 px-0">
                  <Button className="btn-size-xl" color="primary" type="submit">
                    <i className="fa fa-search"></i>
                    <span className="text-nowrap">Tìm kiếm</span>
                  </Button>
                </div>
              </>
            )}
          </Row>
        </form>
        <TableRemotePagination
          data={initData}
          columns={columns}
          page={isDefaultPage ? 1 : pagination.activePage}
          sizePerPage={pagination.itemsPerPage}
          totalSize={totalItems}
          onTableChange={handleTableChange}
          classes="table align-middle table-nowrap"
        />
      </Container >
      {selectedItem &&
        <PermisionUpdate disabled={isApprove === 3 ? true : false} selected={selectedItem} CnpqType={selectedCnpqType} opened={isOpenedModalUpdate} handleCloseModal={handleCloseModal} />
      }
      {
        openApprove &&
        <PermisionApproveDialog typeCnpq={selectedCnpqType} isApprove={isApprove} opened={openApprove} callBackIsLoad={handleCloseModalApprove} />
      }
    </>
  );
};

export default Permision;

