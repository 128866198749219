import { ITitleLevel } from "app/shared/model/title-level.model";

export interface ITitle {
  id?: number | null;
  code?: string;
  name?: string;
  activated?: boolean | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  parentTitle?: ITitle | null;
  titleLevel?: ITitleLevel | null;
}

export const defaultValue: Readonly<ITitle> = {
  activated: true,
};
