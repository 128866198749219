import { notificationMessages } from "app/config/notification-messages";
import { toast } from "react-toastify";

export const showAlert = ({ requestUrl, requestMethod, payload }) => {
    if (requestUrl === "api/account" && requestMethod === "post") {
        if (JSON.parse(payload.config.data).id) {
            toast.success(notificationMessages.userManagement.updated);
        } else {
            toast.success(notificationMessages.userManagement.created);
        }
    }
};
