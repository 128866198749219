import React, { useState, useEffect } from "react";
import {
    Button,
    Container,
    Label,
    Input,
    FormFeedback,
} from "reactstrap";
import { getUrlParameter } from "react-jhipster";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { handlePasswordResetFinish, reset } from "../password-reset.reducer";
import PasswordStrengthBar from "app/shared/layout/password/password-strength-bar";
import { useAppDispatch, useAppSelector } from "app/config/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { REGEX_PASSWORD } from "app/config/constants";

const schema = Yup.object({
    password: Yup.string()
        .required("Vui lòng nhập mật khẩu!")
        .matches(
            REGEX_PASSWORD,
            "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số!"
        )
        .max(50, "Mật khẩu không quá 50 ký tự!"),
    confirmPassword: Yup.string()
        .required("Nhập lại mật khẩu không được để trống!")
        .matches(
            REGEX_PASSWORD,
            "Mật khẩu ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số!"
        )
        .oneOf([Yup.ref("password"), null], "Mật khẩu không trùng khớp!")
        .max(50, "Mật khẩu không quá 50 ký tự!"),
});

export const PasswordResetFinishPage = (
    props: RouteComponentProps<{ key: string }>
) => {
    const history = useHistory();

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
        return () => {
            // Anything in here is fired on component unmount.
            document.body.classList.remove("auth-body-bg");
        };
    }, []);
    const [key] = useState(getUrlParameter("key", props.location.search));
    const dispatch = useAppDispatch();

    useEffect(
        () => () => {
            dispatch(reset());
        },
        []
    );

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        onSubmit: (values) => handleValidSubmit(values.password),
        validationSchema: schema,
        enableReinitialize: true,
    });

    const handleValidSubmit = (newPassword) =>
        dispatch(handlePasswordResetFinish({ key, newPassword }));

    const successMessage = useAppSelector(
        (state) => state.passwordReset.successMessage
    );

    useEffect(() => {
        if (successMessage) {
            toast.success("Cập nhật thành công");
            setTimeout(() => {
                history.push("/");
            }, 1500);
        }
    }, [successMessage]);

    return (
        <Container fluid className="custom-form-login">
            <div className="form-content">
                <form
                    onSubmit={
                        formik.handleSubmit
                    }
                    className="form-body"
                >
                    <div className="text-center title">
                        <h4>Thiết lập mật khẩu</h4>
                    </div>
                    <div className="pass-form-group">
                        <Input
                            type="password"
                            invalid={
                                !!formik
                                    .errors
                                    .password &&
                                formik
                                    .touched
                                    .password
                            }
                            onChange={
                                formik.handleChange
                            }
                            onBlur={
                                formik.handleBlur
                            }
                            value={
                                formik
                                    .values
                                    .password
                            }
                            required
                            className="form-control"
                            name="password"
                            id="basicpill-password-input"
                        />
                        {formik.errors
                            .password &&
                            formik
                                .touched
                                .password && (
                                <FormFeedback>
                                    {
                                        formik
                                            .errors
                                            .password
                                    }
                                </FormFeedback>
                            )}
                        <Label
                            className="form-label floating-label"
                            htmlFor="basicpill-password-input"
                        >
                            Mật khẩu mới{" "}
                            <span className="form-label text-danger">
                                (*)
                            </span>
                        </Label>
                    </div>
                    <PasswordStrengthBar
                        password={
                            formik.values
                                .password
                        }
                    />
                    <div className="pass-form-group">
                        <Input
                            type="password"
                            className="form-control"
                            required
                            name="confirmPassword"
                            invalid={
                                !!formik
                                    .errors
                                    .confirmPassword &&
                                formik
                                    .touched
                                    .confirmPassword
                            }
                            value={
                                formik
                                    .values
                                    .confirmPassword
                            }
                            onChange={
                                formik.handleChange
                            }
                            onBlur={
                                formik.handleBlur
                            }
                            id="basicpill-confirm-password-input"
                        />
                        {formik.errors
                            .confirmPassword &&
                            formik
                                .touched
                                .confirmPassword && (
                                <FormFeedback>
                                    {
                                        formik
                                            .errors
                                            .confirmPassword
                                    }
                                </FormFeedback>
                            )}
                        <Label
                            className="form-label floating-label"
                            htmlFor="basicpill-confirm-password-input"
                        >
                            Nhập lại mật
                            khẩu{" "}
                            <span className="form-label text-danger">
                                (*)
                            </span>
                        </Label>
                    </div>
                    <div className="w-100 d-flex justify-content-center fotter">
                        <Button
                            type="submit"
                            color="primary"
                            className="btn-size-xxl"
                            disabled={!(formik.isValid && formik.dirty)}
                        >
                            Đồng ý
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default PasswordResetFinishPage;
