import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Col, Row } from 'reactstrap';

const defaultSorted = [
    {
      dataField: "lastModifiedDate",
      order: "desc",
    },
];

const TableRemotePagination = ({ defaultSortedValue = defaultSorted, data, columns, page, sizePerPage, onTableChange, totalSize, classes }) => {

    return (
        <PaginationProvider
            pagination={
                paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize
                })
            }
            >
            {
                ({
                paginationProps,
                paginationTableProps
                }) => (
                <div>
                    <BootstrapTable
                        keyField="id"
                        responsive
                        hover
                        condensed
                        bootstrap4
                        striped
                        bordered
                        defaultSorted={defaultSortedValue}
                        columns={ columns }
                        data={ data }
                        classes={ classes }
                        wrapperClasses="table-responsive"
                        headerWrapperClasses="thead-light"
                        remote
                        onTableChange={ onTableChange }
                        wrap
                        { ...paginationTableProps }
                    />
                    {paginationProps.totalSize > 2 && (
                        <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination p-3">
                            <div className="text-md-center d-flex flex-row justify-content-end align-items-center">
                                <div
                                className="total-items pr-3"
                                >
                                <span>
                                    Hiển thị {1 + (paginationProps.page - 1) * paginationProps.sizePerPage } -{" "}
                                    {paginationProps.totalSize <= paginationProps.page * paginationProps.sizePerPage ?
                                        paginationProps.totalSize :
                                        paginationProps.page * paginationProps.sizePerPage}{" "}
                                    / Tổng số{" "}
                                    {paginationProps.totalSize}
                                </span>
                                </div>

                                <div
                                className="pagination pl-3"
                                >
                                <PaginationListStandalone
                                    {...paginationProps}
                                    />
                                </div>
                            </div>
                            </Col>
                        </Row>
                    )}
                </div>
                )
            }
            </PaginationProvider>
    )
};

export default TableRemotePagination;
