import { AUTHORITIES } from "app/config/constants";
import { authorityLabel } from "./common-utils";

export const getAuthorizationOptions = ({ authorities }) => {
    return authorities.reduce((prevValues, currentRole) => {
        if (Object.values(AUTHORITIES).indexOf(currentRole) > -1) {
            return [
                ...prevValues,
                {
                    label: authorityLabel({
                        authority: currentRole,
                    }),
                    value: currentRole,
                },
            ];
        }
        return [...prevValues];
    }, []);
};

export const getOptions = (array: any) => {
    if (array && array.length > 0) {
        return array.map((x) => ({
            ...x,
            value: x.id,
            // label: x.name
            label: x.name.replace(/<[^>]+>/g, '')
        }));
    }
    return array;
};
