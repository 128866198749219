import React, {useEffect, useLayoutEffect} from "react";

import {useAppDispatch, useAppSelector} from "app/config/store";
import {logout} from "app/shared/reducers/authentication";
import {useHistory} from "react-router-dom";

const removeSidebarStyle = () => document.body.classList.remove("modal-open", "no_padding");

export const Logout = () => {

    const history = useHistory();

    const logoutUrl = useAppSelector((state) => state.authentication.logoutUrl);
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        dispatch(logout(history));
        if (logoutUrl) {

            window.location.href = logoutUrl;
        }
    });

    useEffect(() => {
        removeSidebarStyle();
    }, [])

    return (

        <div className="p-5">

            <h4>Logged out successfully!</h4>


        </div>
    );
};

export default Logout;
