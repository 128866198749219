import React, { useState } from "react";
import MenuItem from "app/shared/layout/menus/menu-item";

import { NavDropdown } from "./menu-components";
import { useAppSelector } from "app/config/store";
import SettingsPage from "app/modules/account/settings/settings";
import PasswordPage from "app/modules/account/password/password";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const accountMenuItemsAuthenticated = ({
    isOpenUserSettingPopup,
    isOpenChangePasswordPopup,
    toggleChangePasswordPopup,
    toggleUserSettingPopup,
    logoutPopup,
    isOpenLogoutPopup,
    history
}) => (
    <>
        <MenuItem
            icon="ri-settings-2-line align-middle me-1"
            onClick={toggleUserSettingPopup}
            data-cy="settings"
        >
            <span className="ms-2">Cài đặt</span>
        </MenuItem>
        <SettingsPage
            isOpenUserSettingPopup={isOpenUserSettingPopup}
            handleClose={toggleUserSettingPopup}
            history={history}
        />
        <MenuItem
            icon="ri-lock-unlock-line align-middle me-1"
            onClick={toggleChangePasswordPopup}
            data-cy="passwordItem"

        >
            <span className="ms-2">Đổi mật khẩu </span>
        </MenuItem>
        <PasswordPage isOpenChangePasswordPopup={isOpenChangePasswordPopup}
            handleClose={toggleChangePasswordPopup} />

        <MenuItem
            icon="ri-shut-down-line align-middle me-1 text-danger"
            onClick={logoutPopup}
            data-cy="logout"
        >
            <span className="text-danger ms-2">Đăng xuất</span>
        </MenuItem>

        <Modal isOpen={isOpenLogoutPopup} centered toggle={() => logoutPopup()}>
            <ModalHeader toggle={() => logoutPopup()}>
                <span className="text-danger">Đăng Xuất</span>
            </ModalHeader>
            <ModalBody>
                <span>Bạn có chắc chắn muốn đăng xuất?</span>
                <div className="d-flex justify-content-end gap-3 pt-4">
                    <Button className="btn-size-sm"
                        onClick={logoutPopup}
                        color="secondary"
                    >Hủy</Button>
                    <Button
                        className="btn-size-sm"
                        color="danger"
                        onClick={() => {
                            history.push('/logout')
                        }}
                    >Đồng ý</Button>
                </div>
            </ModalBody>

        </Modal>


    </>
);

const accountMenuItems = () => (
    <>
        <MenuItem
            id="login-item"
            icon="sign-in-alt"
            to="/login"
            data-cy="login"
        >
            Sign in
        </MenuItem>
        <MenuItem icon="user-plus" to="/account/register" data-cy="register">
            Register
        </MenuItem>
    </>
);

export const AccountMenu = ({ isAuthenticated = false }) => {

    const history = useHistory();

    const account = useAppSelector((state) => state.authentication.account);
    const [isOpenUserSettingPopup, setIsOpenUserSettingPopup] = useState(false);

    const [isOpenChangePasswordPopup, setIsOpenChangePasswordPopup] = useState(false);

    const [isOpenLogoutPopup, setIsOpenLogoutPopup] = useState(false);

    const toggleChangePasswordPopup = () => {
        setIsOpenChangePasswordPopup(!isOpenChangePasswordPopup)
    }

    const toggleUserSettingPopup = () => {
        setIsOpenUserSettingPopup(!isOpenUserSettingPopup);
    };

    const logoutPopup = () => {
        setIsOpenLogoutPopup(!isOpenLogoutPopup)

    }


    return (
        <NavDropdown
            icon="user"
            name={`${account.fullName}`}
            id="account-menu"
            data-cy="accountMenu"
            isAuthenticated={isAuthenticated}
        >
            {isAuthenticated
                ? accountMenuItemsAuthenticated({
                    isOpenUserSettingPopup,
                    isOpenChangePasswordPopup,
                    toggleChangePasswordPopup,
                    toggleUserSettingPopup,
                    logoutPopup,
                    isOpenLogoutPopup,
                    history
                })
                : accountMenuItems()}
        </NavDropdown>
    );
};

export default AccountMenu;
