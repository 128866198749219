import axios from "axios";
import {
    createAsyncThunk,
    isFulfilled,
    isPending,
} from "@reduxjs/toolkit";

import { cleanEntity as cleanHistory } from "app/shared/util/entity-utils";
import {
    IQueryParams,
    createEntitySlice,
    serializeAxiosError,
    IHistoryState,
} from "app/shared/reducers/reducer.utils";
import { IHistory, defaultValue } from "app/shared/model/history.model";

const initialState: IHistoryState = {
    loading: false,
    errorMessage: null,
    histories: [],
    history: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
};

const apiUrl = "api/histories";

// Actions

export const getHistories = createAsyncThunk(
    "history/get_histories",
    async ({ page, size, sort, filter }: IQueryParams) => {
        const requestUrl = `${apiUrl}`;
        return axios.get<IHistory[]>(requestUrl,
            {
              params: {
                page,
                size,
                sort,
                filter
              }
            });
    }
);

export const findLatest = createAsyncThunk(
    "history/find_lastest",
    async () => {
        const requestUrl = `${apiUrl}/latest`;
        return axios.get<IHistory>(requestUrl);
    }
);

export const findHistoriesLatest = createAsyncThunk(
    "history/find_histories_lastest",
    async (cnpqTypeId:number) => {
        const requestUrl = `${apiUrl}/latest?cnpqTypeId=${cnpqTypeId}`;
        return axios.get<IHistory[]>(requestUrl);
    }
);


export const getHistory = createAsyncThunk(
    "history/get_history",
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<IHistory>(requestUrl);
    },
    { serializeError: serializeAxiosError }
);

export const createHistory = createAsyncThunk(
    "history/create_history",
    async (history: IHistory, thunkAPI) => {
        const result = await axios.post<IHistory>(apiUrl, cleanHistory(history));
        thunkAPI.dispatch(getHistories({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateHistory = createAsyncThunk(
    "history/update_history",
    async (history: IHistory, thunkAPI) => {
        const result = await axios.put<IHistory>(
            `${apiUrl}/${history.id}`,
            cleanHistory(history)
        );
        thunkAPI.dispatch(getHistories({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const partialUpdateHistory = createAsyncThunk(
    "history/partial_update_history",
    async (history: IHistory, thunkAPI) => {
        const result = await axios.patch<IHistory>(
            `${apiUrl}/${history.id}`,
            cleanHistory(history)
        );
        thunkAPI.dispatch(getHistories({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteHistory = createAsyncThunk(
    "history/delete_history",
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<IHistory>(requestUrl);
        thunkAPI.dispatch(getHistories({}));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const Export = createAsyncThunk(
    "history/export_histories",
    async (filter:any) => {
        const requestUrl = `${apiUrl}/export`;
        return axios.get<IHistory[]>(requestUrl,{
            params: {
              filter
            }
          });
    }
);

// slice

export const HistorySlice = createEntitySlice({
    name: "history",
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getHistory.fulfilled, (state: any, action) => {
                state.loading = false;
                state.history = action.payload.data;
            })
            .addCase(deleteHistory.fulfilled, (state: any) => {
                state.updating = false;
                state.updateSuccess = true;
                state.history = {};
            })
            .addMatcher(isFulfilled(findHistoriesLatest), (state: any, action) => {
                state.loading = false;
                state.histories = action.payload.data;
            })
            .addMatcher(isFulfilled(getHistories), (state, action) => {
                const { data } = action.payload;

                return {
                    ...state,
                    loading: false,
                    histories: data,
                    updateSuccess: false,
                    totalItems: parseInt(
                        action.payload.headers["x-total-count"],
                        10
                    ),
                };
            })
            .addMatcher(
                isFulfilled(createHistory, updateHistory, partialUpdateHistory),
                (state: any, action) => {
                    state.updating = false;
                    state.loading = false;
                    state.updateSuccess = true;
                    state.history = action.payload.data;
                }
            )
            .addMatcher(isPending(getHistories, getHistory, findHistoriesLatest), (state) => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(
                isPending(
                    createHistory,
                    updateHistory,
                    partialUpdateHistory,
                    deleteHistory
                ),
                (state) => {
                    state.errorMessage = null;
                    state.updateSuccess = false;
                    state.updating = true;
                }
            );
    },
});

export const { reset } = HistorySlice.actions;

// Reducer
export default HistorySlice.reducer;
