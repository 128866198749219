import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { ICnpqType, defaultValue } from "app/shared/model/cnpq-type.model";

const initialState: EntityState<ICnpqType> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems : 0
};

const apiUrl = "api/cnpq-types";

// Actions

export const getEntities = createAsyncThunk(
  "cnpqType/fetch_entity_list",
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}`;
    return axios.get<ICnpqType[]>(requestUrl, {
        params: { page, size, sort }
    });
  }
);

export const getAllTitleLevelWidthoutPagination = createAsyncThunk(
  "cnpqType/fetch_cnpqtype_list_widthout_pagination",
  async () => {
    const requestUrl = `${apiUrl}/all?cacheBuster=${new Date().getTime()}`;
    return axios.get<ICnpqType[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "cnpqType/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICnpqType>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "cnpqType/create_entity",
  async (entity: ICnpqType, thunkAPI) => {
    return axios.post<ICnpqType>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "cnpqType/update_entity",
  async (entity: ICnpqType, thunkAPI) => {
    return axios.put<ICnpqType>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "cnpqType/partial_update_entity",
  async (entity: ICnpqType, thunkAPI) => {
    return axios.patch<ICnpqType>(`${apiUrl}/${entity.id}`,cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "cnpqType/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ICnpqType>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CnpqTypeSlice = createEntitySlice({
  name: "cnpqType",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(
            action.payload.headers["x-total-count"],
            10
          )
        };
      })
      .addMatcher(isFulfilled(getAllTitleLevelWidthoutPagination), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity, getAllTitleLevelWidthoutPagination), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = CnpqTypeSlice.actions;

// Reducer
export default CnpqTypeSlice.reducer;
