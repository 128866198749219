import { IWork } from "app/shared/model/work.model";
import { ITitle } from "app/shared/model/title.model";
import { ApproveType } from "app/shared/model/enumerations/approve-type.model";

export interface IPermision {
  id?: number;
  CnpqTypeId?: number;
  approveType?: ApproveType | null;
  assessmentType?: string | null;
  departmentRequiresChange?: string | null;
  approveContent?: string | null;
  comment?: string | null;
  appraisalType?: string | null;
  changeReason?: string | null;
  activated?: boolean | null;
  warning?: boolean | null;
  status?: number | null;
  createdDate?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  work?: IWork | null;
  title?: ITitle | null;
  permisionTitles?: [] | null;
  approveTypes?: any | null;
}

export const defaultValue: Readonly<IPermision> = {
  activated: true,
};
