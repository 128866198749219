import React from "react";

import SidebarContent from "./sidebar-content";

export interface ISideBarProps {
    leftSideBarType: string;
    isAuthenticated: boolean;
    isInProduction: boolean;
    isOpenAPIEnabled: boolean;
    isAdmin: boolean;
}

const Sidebar = (props: ISideBarProps) => {
    return (
        <>
            <div className="vertical-menu">

                <div className="sidebar" style={{height: "870px"}}>
                    <SidebarContent {...props}/>
                </div>

            </div>
        </>
    );
};

export default Sidebar;

