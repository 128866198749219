import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { IPermision, defaultValue } from "app/shared/model/permision.model";

const initialState: EntityState<IPermision> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  childrenEntities: [],
  selectedTypeCnpq:null
};

const apiUrl = "api/permisions";

// Actions

export const getEntity = createAsyncThunk(
  "permision/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPermision>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const fetch = createAsyncThunk(
  "permision/fetch_entity_list",
  async ({ page, size, sort, filter }: IQueryParams) => {
    const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
    return axios.get<IPermision[]>(requestUrl,{
      params: {page,size,sort,filter}
    });
  },
  { serializeError: serializeAxiosError }
);

export const getAllPermissions = createAsyncThunk(
  "permision/fetch_all_entity_list",
  async (cnpqTypeId:number) => {
    const requestUrl = `${apiUrl}/all?cnpqTypeId=${cnpqTypeId}`;
    return axios.get<IPermision[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getAllPermisionApproveByCnpqId = createAsyncThunk(
  "permision/get_entity_list",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/approve?cnpqTypeId=${id}`;
    return axios.get<IPermision[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getChildrenEntities = createAsyncThunk(
  "permision/fetch_entity",
  async (data:any) => {
    const requestUrl = `${apiUrl}/details?workId=${data.wordId}&cnpqTypeId=${data.cnpqTypeId}`;
    return axios.get<IPermision[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const existPermission = createAsyncThunk(
  "permision/fetch_entity",
  async (data:any) => {
    const requestUrl = `${apiUrl}/exist?workId=${data.workId}&cnpqTypeId=${data.cnpqTypeId}`;
    const response = await axios.get<boolean>(requestUrl)
    return response.data;
  },
  { serializeError: serializeAxiosError }
);

export const getPermisionsByWorkIdAndCnpqTypeId = createAsyncThunk(
  "permision/fetch_permisions",
  async (data:any) => {
    const requestUrl = `${apiUrl}/permisions`;
    const response = await axios.put<IPermision[]>(requestUrl,data.cnpqTypeIds);
    return response.data
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "permision/create_entity",
  async (entity: IPermision, thunkAPI) => {
    return axios.post<IPermision>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "permision/update_entity",
  async (entities:Array<IPermision>) => {
    return axios.put<any>(
        `${apiUrl}`,
        entities,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
  },
  { serializeError: serializeAxiosError }
);

export const updatePermisions = createAsyncThunk(
  "permision/update_permision_differen_work",
  async ({entities, status }: { entities : Array<IPermision>, status: string}) => {
    return axios.put<any>(
        `${apiUrl}/update-status/${status}`,
        entities,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
  },
  { serializeError: serializeAxiosError }
);

export const workUpdatePermisions = createAsyncThunk(
  "permision/update_permision_differen_work",
  async (entities:Array<IPermision>) => {
    return axios.put<any>(
        `${apiUrl}/work-update-status`,
        entities,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
  },
  { serializeError: serializeAxiosError }
);

export const exportFile = createAsyncThunk(
  "permision/export",
  async (data:any) =>
    axios.get<any>(`${apiUrl}/export`, {
      params : {
        type: data.type,
        cnpqTypeId : data.cnpqTypeId
      }
    }),
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "permision/partial_update_entity",
  async (entity: IPermision, thunkAPI) => {
    return axios.patch<IPermision>(
        `${apiUrl}/${entity.id}`,
        cleanEntity(entity)
      );
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "permision/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IPermision>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const warning = createAsyncThunk(
  "cnpqType/warning",
  async (data: any, thunkAPI) => {
    return axios.post<any>(`${apiUrl}/warning`, cleanEntity(data),{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PermisionSlice = createEntitySlice({
  name: "permision",
  initialState,
  reducers: {
    setTypeCnpq(state, action) {
      state.selectedTypeCnpq = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(fetch), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(
            action.payload.headers["x-total-count"],
            10
          )
        };
      })
      .addMatcher(isFulfilled(getChildrenEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          childrenEntities: data,
        };
      })
      .addMatcher(isPending(getChildrenEntities), (state) => {
        state.childrenEntities=[];
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity, updatePermisions),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(fetch), (state) => {
        state.errorMessage = null;
        state.entities=[];
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          updatePermisions,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset, setTypeCnpq } = PermisionSlice.actions;

// Reducer
export default PermisionSlice.reducer;
