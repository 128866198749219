import "./home.scss";

import React from "react";

export const Home = () => {
    return (
        <>
            <main>
                <section>
                    <h4>Cẩm nang phân quyền</h4>
                </section>
            </main>
        </>
    );
};

export default Home;
