import React from "react";

export const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner-sub-container">
                <div className="loading-spinner">
                </div>
            </div>
        </div>
    );
}

export const LoadingSpinnerCustom = () => {
    return (
        <div className="spinner-container-custom">
            <div className="spinner-sub-container-custom">
                <div className="loading-spinner-custom">
                </div>
            </div>
        </div>
    );
}

