import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "app/config/store";
import { LoadingSpinner } from "app/shared/util/loading-spinner";
import { AxiosResponse } from "axios";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { createEntityNoNoti, findByCode } from "../title/title.reducer";
import { getAllPermissions, updatePermisions } from "./permision.reducer";
import { getLabelFromValue } from "app/shared/util/common-utils";

export const PermisionApproveDialog = ({ callBackIsLoad, opened, typeCnpq, isApprove }: {
  opened: boolean, callBackIsLoad: any, typeCnpq: any, isApprove: number
}
) => {
  const [loadModal, setLoadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleDefault, setTitleDefault] = useState(null);
  const account = useAppSelector((state) => state.authentication.account);

  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(
    (state) => state.permision.updateSuccess
  );

  useEffect(() => {
    getDefaultTitle()
  }, []);

  
  const handleSaveData = () => {
    setLoading(true)
    if (!titleDefault) {
      handleCreateTitle().then((result: any) => {
        if (result && result.payLoad)
          fetchDataFromProps(result.payload.data);
      });
    }
    else {
      fetchDataFromProps();
    }
  }


  const fetchDataFromProps = (title = null) => {
    let dataPayload = []
    dispatch(getAllPermissions(typeCnpq.id)).then((res: any) => {
            if (res && res.payload) {
        _.map(res.payload.data, function square(value, index) {
          const definedPers = [{
            activated: true,
            cnpqTypeId: typeCnpq.id,
            nameCnpq: value.name,
            cnpqTypeName: typeCnpq.name,
            titleId: title ? title.id : titleDefault.id,
            title: titleDefault,
            TitleName: title ? title.name : titleDefault.name,
            workId: value.id,
            approveType: 'No',
            assessmentType: 'No',
            work: {
              id: value.id,
              code: value.code
            }
          }]
          dataPayload = dataPayload.concat(value.permissions.length > 0 
            ? value.permissions.map(x => {
              return {
                ...x,
                nameCnpq: value.name,
                cnpqTypeName: typeCnpq.name,
                approveType: getLabelFromValue(x.approveType)
              }
            }) : value.deleteFlag !== true ? definedPers : [])
          return dataPayload;
        })
        confirmApprove(dataPayload)
      }
    });
  };

  const handleCreateTitle = async () => {
    const payLoad = {
      name: "default",
      code: "default",
      activated: true
    }
    const response = await dispatch(createEntityNoNoti(payLoad));
    const result = response.payload as AxiosResponse
    if (result && result.data) {
      setTitleDefault(result.data)
    }

  }

  const getDefaultTitle = async () => {
    const response = await dispatch(findByCode("default"));
    const result = response.payload as AxiosResponse
    if (result && result.data) {
      setTitleDefault(result.data)
    }
  }

  const handleClose = (reload = false) => {
    setLoadModal(false);
    callBackIsLoad(reload);

  };

  useEffect(() => {
    setLoadModal(opened)
  }, [opened]);

  useEffect(() => {
    if (updateSuccess && loadModal) {
      toast.success("Phê duyệt thành công")
      handleClose(updateSuccess);
    }
  }, [updateSuccess]);

  const confirmApprove = async (data) => {
    if (data.length > 0) {

      const IsModifined = data.every(x => x.status === 0 || x.status === 1);

      const payload = data.map(x => {
        const getReadableStatus = (status: number) => {
          if (IsModifined) {
            return 3;
          } else {
            if (data.some(y => y.status === 2)) {
              return status === 2 ? 2 : 3
            }
            else {
              // return status === 3 ? 4 : 3;
              if(isApprove === 4 || isApprove === 0) {
                return 3
              } else {
                return status === 3 ? 4 : 3;
              }
            }
          }
        };

        return {
          ...x,
          lastModifiedBy: account.email,
          status: getReadableStatus(x.status),
        }
      })
      await dispatch(updatePermisions({ entities: payload, status: getIsApproved(isApprove)}))
      setLoading(false)
    }
    else {
      toast.warning("Đầu việc chưa được phân quyền. Vui lòng kiểm tra lại")
      setLoading(false)
    }
  };

  const getIsApproved = (status) => {
    if(status === 4 || status === 0) {
      return "approve"
    }
    if(status === 3) {
      return "accept"
    }
  }

  return (
    <>
      <Modal isOpen={loadModal} backdrop={"static"} toggle={() => { handleClose() }}>
        <ModalHeader toggle={() => { handleClose() }}>Xác nhận phê duyệt</ModalHeader>
        {loading ? <LoadingSpinner /> : null}
        <ModalBody>
          Bạn có chắc chắn muốn {isApprove === 4 || isApprove === 0 ? "phê duyệt" : "xác nhận"} CNPQ {typeCnpq?.name} không?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveData}>
            <i className="mdi mdi-check" />
            Đồng ý
          </Button>
          <Button color="secondary" onClick={() => { handleClose() }}>
            <FontAwesomeIcon icon="ban" />
            Hủy bỏ
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PermisionApproveDialog;
