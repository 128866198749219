import React, { useEffect, useState } from "react";
import { Alert, Button, Input, Container } from "reactstrap";
import "./password-reset-init.scss";

import { handlePasswordResetInit, reset } from "../password-reset.reducer";
import { useAppDispatch } from "app/config/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

const schema = Yup.object().shape({
    email: Yup.string()
        .required("Vui lòng nhập địa chỉ email!")
        .email("Tên đăng nhập phải có định dạng Email!"),
});

export const PasswordResetInit = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
        return () => {
            // Anything in here is fired on component unmount.
            document.body.classList.remove("auth-body-bg");
        };
    }, []);

    useEffect(() => {
        dispatch(reset());
    }, []);

    const handleValidSubmit = (values, { setSubmitting }) => {
        dispatch(handlePasswordResetInit(values.email)).then(result => {
            if (result.payload) {
                setIsSuccess(true);
            }
            setSubmitting(false);
        })
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: handleValidSubmit,
        validationSchema: schema,
    });

    return (
        <Container fluid className="custom-form-login">
            {isSuccess ? (
                <div className="form-content form-content-message-success">
                    <img
                        src="content/images/success.png"
                        alt="success"
                    />
                    <p className="password-reset-init-title">
                        Mật khẩu mới đã được gửi tới địa chỉ Email
                        của bạn. Vui lòng kiểm tra hộp thư đến
                    </p>
                    <p className="password-reset-init-description">
                        Vui lòng kiểm tra tất cả hộp thư tại Email
                        bao gồm Thư Rác/ Spam/ Junk, Quảng Cáo, Xã Hội...
                    </p>
                </div>
            ) : (
                <div className="form-content">
                    <i className="mdi mdi-arrow-left icon-back" onClick={() => { history.goBack() }} ></i>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form-body"
                    >
                        <div className="text-center title">
                            <h4>
                                Quên mật khẩu
                            </h4>
                        </div>
                        {!!formik.errors.email && formik.touched.email && (
                            <Alert
                                className="box-alert"
                                color="danger"
                            >
                                <span>
                                    Địa chỉ Email chưa chính xác
                                </span>
                            </Alert>
                        )}
                        <div className="auth-form-group-custom">
                            <Input
                                className="form-control"
                                invalid={
                                    !!formik.errors.email &&
                                    !!formik.touched.email
                                }
                                type="text"
                                name="email"
                                maxLength={255}
                                required
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <span className="floating-label">
                                Nhập địa chỉ Email
                            </span>
                        </div>

                        <div className="w-100 d-flex justify-content-center fotter">
                            <Button
                                color="primary"
                                className="btn-size-xxl"
                                type="submit"
                                disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                            >
                                Lấy mật khẩu
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </Container>
    );
};

export default PasswordResetInit;
