import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected

} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  serializeAxiosError,
  IWorkState,
} from "app/shared/reducers/reducer.utils";
import { IWork, defaultValue } from "app/shared/model/work.model";
import { ITEMS_PER_PAGE } from "app/shared/util/pagination.constants";

const initialState: IWorkState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  importSuccess: false,
  cnpqTypesOptions: []
};

const apiUrl = "api/works";

// Actions

export const getEntities = createAsyncThunk(
  "work/fetch_entity_list",
  async ({ page = 0, size = ITEMS_PER_PAGE, sort, filter }: IQueryParams) => {
    const requestUrl = `${apiUrl}`;
    return axios.get<IWork[]>(requestUrl,
      {
        params:{
          page,
          size,
          sort,
          filter
        }
      });
  }
);

export const getAllTitleLevelWidthoutPagination = createAsyncThunk(
  "work/fetch_works_list_widthout_pagination",
  async () => {
    const requestUrl = `${apiUrl}/all`;
    return axios.get<IWork[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  "work/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IWork>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  "work/create_entity",
  async (entity: IWork, thunkAPI) => {
    return axios.post<IWork>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "work/update_entity",
  async (entity: IWork, thunkAPI) => {
    return axios.put<IWork>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "work/partial_update_entity",
  async (entity: IWork, thunkAPI) => {
    return axios.patch<IWork>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "work/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IWork>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const importFile = createAsyncThunk(
  "work/import",
  async (payload: any) =>
    axios.post<any>(`${apiUrl}/import`, payload.fileUpload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(event) {
        payload.setProgress(Math.round((100 * event.loaded) / event.total));
      }
    }),
  { serializeError: serializeAxiosError }
);


export const exportFile = createAsyncThunk(
  "work/export",
  async () =>
    axios.get<any>(`${apiUrl}/export`, {
    }),
  { serializeError: serializeAxiosError }
);

export const warning = createAsyncThunk(
  "work/warning",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/warning?workId=${id}`;
    return axios.get<IWork>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const exporWorks= createAsyncThunk(
  "work/export_works",
  async (filter:any) => {
      const requestUrl = `${apiUrl}/export_works`;
      return axios.get<IWork[]>(requestUrl,{
          params: {
            filter
          }
      });
  }
);


// slice

export const WorkSlice = createEntitySlice({
  name: "work",
  initialState,
  reducers: {
    setCnpqTypesOptions(state, action) {
      return {
        ...state,
        cnpqTypesOptions: action.payload
      }
    },
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          updateSuccess : false,
          totalItems: parseInt(
            action.payload.headers["x-total-count"],
            10
          )
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(importFile), (state) => {
        state.importSuccess = false;
        state.loading = true;
      })
      .addMatcher(isFulfilled(importFile), (state) => {
        state.importSuccess = true;
        state.loading = false;
      })
      .addMatcher(isRejected(importFile), (state) => {
        state.importSuccess = false;
        state.loading = false;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { setCnpqTypesOptions, reset } = WorkSlice.actions;

// Reducer
export default WorkSlice.reducer;
