import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons/faAsterisk";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faCloud } from "@fortawesome/free-solid-svg-icons/faCloud";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faFlag } from "@fortawesome/free-solid-svg-icons/faFlag";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faThList } from "@fortawesome/free-solid-svg-icons/faThList";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons/faUsersCog";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";

import { library } from "@fortawesome/fontawesome-svg-core";

export const loadIcons = () => {
  return new Promise<void>((resolve) => {
    library.add(
      faArrowLeft,
      faAsterisk,
      faBan,
      faBell,
      faBook,
      faCloud,
      faCogs,
      faDatabase,
      faEye,
      faFlag,
      faHeart,
      faHome,
      faList,
      faLock,
      faPencilAlt,
      faPlus,
      faRoad,
      faSave,
      faSignInAlt,
      faSignOutAlt,
      faSearch,
      faSort,
      faSync,
      faTachometerAlt,
      faTasks,
      faThList,
      faTimesCircle,
      faTrash,
      faUser,
      faUserPlus,
      faUsers,
      faUsersCog,
      faWrench
    );
    resolve();
  });
  
};
