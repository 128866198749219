export enum ApproveType {
  No = "No",
  TD = "TĐ",
  TD_1 = "TĐ1",
  TD_2 = "TĐ2",
  TD_3 = "TĐ3",
  TD_4 = "TĐ4",
  TD_STAR = "TĐ(*)",
  PD = "PD",
}
