import React from "react";
import { Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Register from "app/modules/account/register/register";
import Activate from "app/modules/account/activate/activate";
import PasswordResetInit from "app/modules/account/password-reset/init/password-reset-init";
import PasswordResetFinish from "app/modules/account/password-reset/finish/password-reset-finish";
import PrivateRoute from "app/shared/auth/private-route";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import PageNotFound from "app/shared/error/page-not-found";
import { AUTHORITIES } from "app/config/constants";
import Login from "app/modules/login/login";
import Home from "./modules/home/home";
import Logout from "app/modules/login/logout";
import Permision from "./modules/permision/permision";

const loading = <div>Đang tải...</div>;

export interface IRoterProps {
    isAuthenticated: boolean;
}

const Account = Loadable({
    loader: () =>
        import(/* webpackChunkName: "account" */ "app/modules/account"),
    loading: () => loading,
});

const Admin = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "administration" */ "app/modules/administration"
        ),
    loading: () => loading,
});

const Modules = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "administration" */ "app/modules/routes"
        ),
    loading: () => loading,
});


const Routes: React.FC<IRoterProps> = ({ isAuthenticated }) => {
    return (
        <div className="view-routes">
            <Switch>
                {isAuthenticated ? (
                    <>
                        <PrivateRoute path="/" exact component={Permision} />
                        <ErrorBoundaryRoute path="/logout" component={Logout} />

                        <ErrorBoundaryRoute
                            path="/cnpq"
                            component={Home}
                        />

                        <ErrorBoundaryRoute
                            path="/account/register"
                            component={Register}
                        />
                        <ErrorBoundaryRoute
                            path="/account/activate/:key?"
                            component={Activate}
                        />

                        <PrivateRoute
                            path="/admin"
                            component={Admin}
                            hasAnyAuthorities={[AUTHORITIES.ADMIN]}
                        />
                        <PrivateRoute
                            path="/account"
                            component={Account}
                            hasAnyAuthorities={[
                                AUTHORITIES.ADMIN,
                                AUTHORITIES.USER,
                            ]}
                        />
                        <PrivateRoute
                            component={Modules}
                            hasAnyAuthorities={[
                                AUTHORITIES.ADMIN,
                                AUTHORITIES.USER,
                            ]}
                        />
                        <ErrorBoundaryRoute
                            path="/404"
                            component={PageNotFound}
                        />
                    </>
                ) : (
                    <>
                        <ErrorBoundaryRoute path="/" exact component={Login} />

                        <ErrorBoundaryRoute
                            path="/account/reset/request"
                            component={PasswordResetInit}
                        />
                        <ErrorBoundaryRoute
                            path="/account/reset/finish/:key?"
                            component={PasswordResetFinish}
                        />

                    </>
                )}
            </Switch>
        </div>
    );
};

export default Routes;
