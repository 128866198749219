import axios from "axios";
import {
  createAsyncThunk,
  isFulfilled,
  isPending
} from "@reduxjs/toolkit";

import { cleanEntity } from "app/shared/util/entity-utils";
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from "app/shared/reducers/reducer.utils";
import { ITitle, defaultValue } from "app/shared/model/title.model";

const initialState: EntityState<ITitle> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  childrenEntities: [],
  totalItems: 0
};

const apiUrl = "api/titles";

// Actions

export const getEntities = createAsyncThunk(
  "title/fetch_entity_list",
  async ({ page, size, sort, filter }: IQueryParams) => {
    const requestUrl = `${apiUrl}`;
    return axios.get<ITitle[]>(requestUrl, {
        params: {page, size, sort, filter}
    });
  }
);

export const getAllTitleWidthoutPagination = createAsyncThunk(
  "title/fetch_title_list_widthout_pagination",
  async () => {
    const requestUrl = `${apiUrl}/all`;
    const response = await axios.get<ITitle[]>(requestUrl);
    return response.data
  }
);

export const getEntity = createAsyncThunk(
  "title/fetch_entity",
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITitle>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getChildrenEntities = createAsyncThunk(
  "title/fetch_entity",
  async (parentId: string | number) => {
    const requestUrl = `${apiUrl}/childrens/${parentId}`;
    return axios.get<ITitle[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
  "title/create_entity",
  async (entity: ITitle, thunkAPI) => {
    return axios.post<ITitle>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const createEntityNoNoti = createAsyncThunk(
  "title/create_entity_no_noti",
  async (entity: ITitle, thunkAPI) => {
    return axios.post<ITitle>(`${apiUrl}/no-noti`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  "title/update_entity",
  async (entity: ITitle, thunkAPI) => {
    return axios.put<ITitle>(
        `${apiUrl}/${entity.id}`,
        cleanEntity(entity)
      );
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  "title/partial_update_entity",
  async (entity: ITitle, thunkAPI) => {
    return axios.patch<ITitle>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  "title/delete_entity",
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<ITitle>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const findByCode = createAsyncThunk(
  "title/find_by_code",
  async (data: string, thunkAPI) => {
    const requestUrl = `${apiUrl}/code/${data}`;
    return axios.get<ITitle>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const TitleSlice = createEntitySlice({
  name: "title",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, (state) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          updateSuccess : false,
          totalItems: parseInt(
            action.payload.headers["x-total-count"],
            10
          )
        };
      })
      .addMatcher(isFulfilled(getChildrenEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          childrenEntities: data,
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), (state) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        (state) => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = TitleSlice.actions;

// Reducer
export default TitleSlice.reducer;
