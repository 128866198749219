import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/config/store";
import { login, getLockoutEnd } from "app/shared/reducers/authentication";
import {
    Button,
    Container,
    FormFeedback,
    Input,
    Label
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const schema = Yup.object({
    username: Yup.string().trim()
        .required("Vui lòng nhập thông tin đăng nhập!")
        .email("Tên đăng nhập phải có định dạng Email!"),
    password: Yup.string().trim().required("Vui lòng nhập mật khẩu!"),
});

export const Login = (props: RouteComponentProps<any>) => {

    const history = useHistory();

    useEffect(() => {
        document.body.classList.add("auth-body-bg");
        return () => {
            // Anything in here is fired on component unmount.
            document.body.classList.remove("auth-body-bg");
        };
    }, [history.location.pathname]);

    const [isDisabled, setDisabled] = useState(false);

    const timeLockoutEnd: string = useAppSelector(
        (state) => state.authentication.LockoutEnd
    );

    const timeLock = (value) => {
        if (value) {
            dispatch(getLockoutEnd(value));
        }
    }

    useEffect(() => {
        if (timeLockoutEnd) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [timeLockoutEnd]);

    useEffect(() => {
        if (isDisabled) {
            const miliseconds =
                new Date(timeLockoutEnd).getTime() - new Date().getTime();
            setTimeout(() => {
                setDisabled(false);
            }, miliseconds);
        }
    }, [isDisabled]);

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
            rememberMe: null,
        },
        onSubmit: (values) => handleLoginSubmit(values),
        validationSchema: schema,
        enableReinitialize: true,
    });

    const dispatch = useAppDispatch();

    const handleLoginSubmit = (values) => {
        dispatch(login(values.username.trim(), values.password.trim(), history, values.rememberMe));
    };

    const handleChangeUserName = (value) => {
        formik.handleChange(value)
        timeLock(value.currentTarget.value)
    }

    return (
        <Container fluid className="custom-form-login">
            <div className="form-content">
                <form
                    onSubmit={
                        formik.handleSubmit
                    }
                    className="form-body"
                >
                    <div className="text-center logo">
                        <Link to="/" className="">
                            <img src="content/images/logo-vinhomes-2.svg" />
                        </Link>
                    </div>
                    <div className="auth-form-group-custom">
                        <Input
                            type="text"
                            autoFocus
                            invalid={
                                !!formik
                                    .errors
                                    .password &&
                                formik
                                    .touched
                                    .password
                            }
                            onChange={
                                handleChangeUserName
                            }
                            onBlur={
                                formik.handleBlur
                            }
                            value={
                                formik
                                    .values
                                    .username
                            }
                            required
                            maxLength={255}
                            className="form-control"
                            name="username"
                            id="basicpill-username-input"
                        />
                        {!!formik.errors
                            .username &&
                            formik.touched
                                .username && (
                                <>
                                    <FormFeedback>
                                        {
                                            formik
                                                .errors
                                                .username
                                        }
                                    </FormFeedback>

                                    <div className="mb-negative-25"></div>
                                </>
                            )}
                        <span className="floating-label">
                            Tên đăng nhập
                        </span>
                    </div>

                    <div className="auth-form-group-custom">
                        <Input
                            type="password"
                            invalid={
                                !!formik
                                    .errors
                                    .password &&
                                formik
                                    .touched
                                    .password
                            }
                            onChange={
                                formik.handleChange
                            }
                            onBlur={
                                formik.handleBlur
                            }
                            value={
                                formik
                                    .values
                                    .password
                            }
                            required
                            maxLength={50}
                            className="form-control"
                            name="password"
                            id="basicpill-password-input"
                        />
                        {!!formik.errors
                            .password &&
                            formik.touched
                                .password && (
                                <>
                                    <FormFeedback>
                                        {
                                            formik
                                                .errors
                                                .password
                                        }
                                    </FormFeedback>

                                    <div className="mb-negative-25"></div>
                                </>
                            )}
                        <span className="floating-label">
                            Mật khẩu
                        </span>
                    </div>

                    <div className="form-check action-remember col-md-6 float-start d-flex justify-content-start">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rememberMe"
                            defaultChecked={
                                false
                            }
                            value={
                                formik
                                    .values
                                    .rememberMe
                            }
                            onChange={
                                formik.handleChange
                            }
                            onBlur={
                                formik.handleBlur
                            }
                        />{" "}
                        <Label
                            check
                            className="form-check-label-login"
                        >
                            Lưu mật khẩu
                        </Label>
                    </div>

                    <div className="action-forgot col-md-6 justify-content-end">
                        <Link
                            to="/account/reset/request"
                        >
                            Quên mật
                            khẩu?
                        </Link>
                    </div>

                    <div className="w-100 d-flex justify-content-center fotter">
                        <Button
                            disabled={
                                isDisabled ||
                                !(formik.isValid)
                            }
                            color="primary"
                            className="btn-size-xxl"
                            type="submit"
                            data-cy="submit"
                        >
                            Đăng nhập
                        </Button>
                    </div>
                    <Label className="fotter-label justify-content-center">Đăng nhập tài khoản <span> VINGROUP</span></Label>
                </form>
            </div>
        </Container>
    );
};

export default Login;

