import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/flatpickr.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "./app.scss";
import "app/config/dayjs.ts";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { useAppDispatch, useAppSelector } from "app/config/store";
import { getSession } from "app/shared/reducers/authentication";
import { getProfile } from "app/shared/reducers/application-profile";
import { hasAnyAuthority } from "app/shared/auth/private-route";
import ErrorBoundary from "app/shared/error/error-boundary";
import { AUTHORITIES } from "app/config/constants";
import AppRoutes from "app/routes";
import Sidebar from "app/shared/layout/sidebar/sidebar";

const baseHref = document
    .querySelector("base")
    .getAttribute("href")
    .replace(/\/$/, "");

export const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getSession());
        dispatch(getProfile());
    }, []);

    const [leftSideBarType] = useState("default");

    const isAuthenticated = useAppSelector(
        (state) => state.authentication.isAuthenticated
    );

    const isAdmin = useAppSelector((state) =>
        hasAnyAuthority(state.authentication.account.authorities, [
            AUTHORITIES.ADMIN,
        ])
    );

    const isInProduction = useAppSelector(
        (state) => state.applicationProfile.inProduction
    );
    const isOpenAPIEnabled = useAppSelector(
        (state) => state.applicationProfile.isOpenAPIEnabled
    );

    // useEffect(() => {
    //     dispatch(getSession());
    //     dispatch(getProfile());
    // }, []);

    return (
        <Router basename={baseHref}>
            <div className="app-container">
                <ToastContainer
                    position={toast.POSITION.TOP_LEFT}
                    className="toastify-container"
                    toastClassName="toastify-toast"
                    autoClose={1200}
                />
                <div id="layout-wrapper">
                    {isAuthenticated && (
                        <>
                            <ErrorBoundary>
                                <Sidebar
                                    leftSideBarType={leftSideBarType}
                                    isAuthenticated={isAuthenticated}
                                    isInProduction={isInProduction}
                                    isOpenAPIEnabled={isOpenAPIEnabled}
                                    isAdmin={isAdmin}
                                />
                            </ErrorBoundary>
                        </>
                    )}
                    <div className="main-content">
                        <div className="page-content">
                            <ErrorBoundary>
                                <AppRoutes isAuthenticated={isAuthenticated} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default App;
